import React, { Component } from "react";
import { fabric } from "fabric";
import { SketchPicker } from "react-color";
import { Row, Col, Container, Form, Input } from "reactstrap";
import {
  addToHistory,
  addToHistory2,
  removeStates,
  removeStates2,
} from "../components/CanvasHistory";
import { getCanvasBySide } from "./Helpers.js";
import { TabPanel } from "react-web-tabs";
import { TabContent, TabPane } from "reactstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { IoStarSharp, IoTriangleSharp } from "react-icons/io5";
import Tooltip from "react-tooltip-lite";
import {
  DEFAULT_FONT_PROPS,
  SAMPLE1_FONT_PROPS1,
  SAMPLE1_FONT_PROPS2,
  SAMPLE2_FONT_PROPS1,
  SAMPLE2_FONT_PROPS2,
  SAMPLE2_FONT_PROPS3,
  SAMPLE3_FONT_PROPS1,
  SAMPLE3_FONT_PROPS2,
  SAMPLE4_FONT_PROPS1,
  SAMPLE4_FONT_PROPS2,
  SAMPLE5_FONT_PROPS1,
  SAMPLE5_FONT_PROPS2,
  SAMPLE6_FONT_PROPS1,
  SAMPLE6_FONT_PROPS2,
  SAMPLE6_FONT_PROPS3,
  SAMPLE7_FONT_PROPS1,
  SAMPLE7_FONT_PROPS2,
  SAMPLE8_FONT_PROPS1,
  SAMPLE8_FONT_PROPS2,
  SAMPLE8_FONT_PROPS3,
} from "../constants";
//import { getDpi } from "./Helpers";
import { unique, deleteCanvasBg } from "./Helpers";
//import Modal from "react-bootstrap/Modal";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import "./modal.css";
import config from "../../config";
import axios from "axios";
import { LuImageOff } from "react-icons/lu";
import Spin from "../components/BootstrapSpinner.js";
import { BsFillInfoCircleFill } from "react-icons/bs";
import Modal from "react-bootstrap/Modal";

const INIT_SOLID_COLORS = [
  "#d0021b",
  "#f5a623",
  "#f8e71c",
  "#8b572a",
  "#b8e986",
  "#417505",
  "#4a90e2",
  "#50e3ca",
  "#000000",
  "#fff",
];
const optionStyles = (styles) => {
  return {
    ...styles,
    cursor: "pointer",
  };
};
class LeftPanel extends Component {
  state = {
    displaybgColorPicker: false,
    displaygrad1ColorPicker: false,
    displaygrad2ColorPicker: false,
    canvasScale: 1,
    SCALE_FACTOR: 1.2,
    bgcolArray: [],
    backgroundcolor: "",
    grad1color: "black",
    grad2color: "black",
    apiImg: [],
    clipartData: [],
    clipartImages: [],
    subcat: "",
    page: 1,
    searchkey: "sport",
    activeTab: "1",
    imgactiveTab: "1",
    unsplashImg: [],
    unsplashsearchkey: "woods",
    pagenum: 1,
    isOpen: false,
    isDPIOpen: false,
    isLargeImage: false,
    uploadImageWidth: null,
    imageDPI: null,
    data: [],
    offset: 0,
    perPage: 2,
    currentPage: 0,
    showPopover: [],
    frontValues: [],
    colorCat: "standard",
    selectedImages: [],
    clipartCategories: [],
    selectedCategory: 1,
    selectedImage: "",
    newSortedArray: [],
    systemType: "",
  };

  constructor(props) {
    super(props);
    this.imgScrollRef = React.createRef();
    this.popoverRef = React.createRef();
    this.shapeUpload = this.shapeUpload.bind(this);
  }
  handleCategorySelect = (event) => {
    this.setState({
      selectedCategory: event.value,
    });
  };

  closeDPIModal = () => {
    this.setState({
      isDPIOpen: false,
      isLargeImage: false,
    });
  };

  componentDidMount() {
    this.setState({
      IsLoaderClipart: true,
    });
    const ua = navigator.userAgent;
    if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
      this.setState({
        systemType: "tablet",
      });
    } else if (
      /Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(
        ua
      )
    ) {
      this.setState({
        systemType: "mobile",
      });
    } else {
      this.setState({
        systemType: "desktop",
      });
    }
    document.addEventListener("dragover", this.handleDragOver);
    document.addEventListener("drop", this.handleDrop);
    const headers = {
      "Content-Type": "application/json;charset=UTF-8",
    };
    let apiDynamicUrlAddress = config.default.api1.host;
    let apiUrl = `${apiDynamicUrlAddress}/clipart`;
    axios
      .get(apiUrl, {
        mode: "cors",
        headers: headers,
      })
      .then((response) => {
        if (response.data.code === 200) {
          const options = response.data.data.map((category) => ({
            value: category.id,
            label: category.name,
            images: category.images,
            subcategories: category.subcategories,
          }));
          this.setState({
            clipartCategories: options,
            IsLoaderClipart: false,
          });
        } else {
          this.setState({
            clipartCategories: [],
          });
        }
      })
      .catch((error) => {
        toast.error("Clipart API Failed, Try again later", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });

    let bgcolArray = localStorage.getItem("bgcolors");
    if (bgcolArray) {
      bgcolArray = JSON.parse(bgcolArray);
      this.setState({
        bgcolArray: bgcolArray,
      });
    }
    this.imgScrollRef.current.addEventListener("scroll", () => {
      if (
        this.imgScrollRef.scrollTop + this.imgScrollRef.clientHeight >=
        this.imgScrollRef.scrollHeight
      ) {
        this.incermentpage();
      }
    });
    if (this.props.canvas) {
      this.props.canvas.off("object:added", this.functionTextValues);
      this.props.canvas.on("object:added", this.functionTextValues);
    }
  }
  componentWillUnmount() {
    document.removeEventListener("dragover", this.handleDragOver);
    document.removeEventListener("drop", this.handleDrop);
  }
  componentDidUpdate() {
    if (this.props.canvas) {
      this.props.canvas.off("object:added", this.functionTextValues);
      this.props.canvas.on("object:added", this.functionTextValues);
      this.props.canvas.off("object:modified", this.functionTextValues);
      this.props.canvas.on("object:modified", this.functionTextValues);
    }
  }

  functionTextValues = (e) => {
    this.updateTextValues(e);
  };

  updateTextValues = () => {
    if (this.props.canvas) {
      let frontCanvas = getCanvasBySide(this.props.allCanvasList, "front");
      let valueFront = [];
      this.setState({ frontValues: [] });
      frontCanvas.getObjects().forEach((item) => {
        if (item.type === "textbox") {
          valueFront.push(item);
        }
      });
      this.setState({ frontValues: valueFront });
    }
  };
  //Common method to addtext
  addText = (type, text, isVerse) => {
    isVerse = !!isVerse;
    var canvas = this.props.canvas;
    const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
    const canvasHorizontalCenter = fabric.Canvas.originalWidth / 2;
    let fontSize = DEFAULT_FONT_PROPS.text_size;
    if (type === "heading") {
      fontSize = DEFAULT_FONT_PROPS.heading_size;
    } else if (type === "title") {
      fontSize = DEFAULT_FONT_PROPS.title_size;
    }
    let top = 50;
    const textProperties = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: DEFAULT_FONT_PROPS.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: fontSize,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#000000",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 15,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 200,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    var HideControls = {
      mb: false,
      mt: false,
    };
    var textObj = new fabric.Textbox(text, textProperties);
    textObj.setControlsVisibility(HideControls);
    canvas.setActiveObject(textObj);
    canvas.calcOffset();
    let canvasVerticalPosition;
    let canvasHorizontalPosition;
    if (type === "heading") {
      //set -25% from vertically center position
      canvasVerticalPosition =
        canvasVerticalCenter - (30 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.96 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
    } else if (type === "description") {
      //add +15% from vertically center position
      canvasVerticalPosition =
        canvasVerticalCenter + (30 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.7 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
    } else if (type === "title") {
      canvasVerticalPosition =
        canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
    }
    textObj.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
    });
    canvas.add(textObj);
    canvas.renderAll();
    if (canvas) {
      let frontCanvas = getCanvasBySide(this.props.allCanvasList, "front");
      let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
      removeStates();
      removeStates2();
      addToHistory(frontCanvas);
      addToHistory2(backCanvas);
    }
  };
  addSampleText1 = (type, isVerse) => {
    isVerse = !!isVerse;
    var canvas = this.props.canvas;
    const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
    const canvasHorizontalCenter = fabric.Canvas.originalWidth / 2;
    let top = 50;
    const textProperties = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE1_FONT_PROPS1.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE1_FONT_PROPS1.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#595b61",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 120,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    const textProperties1 = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE1_FONT_PROPS2.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE1_FONT_PROPS2.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#2c2e35",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 120,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    var HideControls = {
      mb: false,
      mt: false,
    };
    var textObj = new fabric.Textbox("besties", textProperties);
    var textObj1 = new fabric.Textbox("FOREVER", textProperties1);
    textObj.setControlsVisibility(HideControls);
    textObj1.setControlsVisibility(HideControls);
    canvas.setActiveObject(textObj);
    canvas.calcOffset();
    let canvasVerticalPosition;
    let canvasHorizontalPosition;
    if (type === "heading") {
      //set -25% from vertically center position
      canvasVerticalPosition =
        canvasVerticalCenter - (30 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.96 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
      textObj1.top = canvasVerticalPosition;
      textObj1.left = canvasHorizontalPosition;
    } else if (type === "description") {
      //add +15% from vertically center position
      canvasVerticalPosition =
        canvasVerticalCenter + (30 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.7 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
      textObj1.top = canvasVerticalPosition;
      textObj1.left = canvasHorizontalPosition;
    } else if (type === "title") {
      canvasVerticalPosition =
        canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
      textObj1.top = canvasVerticalPosition;
      textObj1.left = canvasHorizontalPosition;
    }
    textObj.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: fabric.Canvas.originalHeight / 2,
    });
    textObj1.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: textObj.top + 40,
    });
    canvas.add(textObj, textObj1);
    var selectedObjects = [textObj, textObj1];
    this.displayCommonControls(selectedObjects);
    canvas.renderAll();
    if (canvas) {
      let frontCanvas = getCanvasBySide(this.props.allCanvasList, "front");
      let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
      removeStates();
      removeStates2();
      addToHistory(frontCanvas);
      addToHistory2(backCanvas);
    }
  };
  addSampleText2 = (type, isVerse) => {
    isVerse = !!isVerse;
    var canvas = this.props.canvas;
    const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
    const canvasHorizontalCenter = fabric.Canvas.originalWidth / 2;

    let top = 50;
    const textProperties = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE2_FONT_PROPS1.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE2_FONT_PROPS1.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#6b516b",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 120,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    const textProperties1 = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE2_FONT_PROPS2.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE2_FONT_PROPS2.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#6b516b",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 120,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    const textProperties2 = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE2_FONT_PROPS3.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE2_FONT_PROPS3.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#422336",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 120,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    var HideControls = {
      mb: false,
      mt: false,
    };
    var textObj = new fabric.Textbox("EAT", textProperties);
    var textObj1 = new fabric.Textbox("SLEEP", textProperties1);
    var textObj2 = new fabric.Textbox("REPEAT", textProperties2);
    textObj.setControlsVisibility(HideControls);
    textObj1.setControlsVisibility(HideControls);
    textObj2.setControlsVisibility(HideControls);
    canvas.setActiveObject(textObj);
    canvas.calcOffset();
    let canvasVerticalPosition;
    let canvasHorizontalPosition;
    if (type === "heading") {
      //set -25% from vertically center position
      canvasVerticalPosition =
        canvasVerticalCenter - (30 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.96 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
      textObj1.top = canvasVerticalPosition;
      textObj1.left = canvasHorizontalPosition;
    } else if (type === "description") {
      //add +15% from vertically center position
      canvasVerticalPosition =
        canvasVerticalCenter + (30 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.7 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
      textObj1.top = canvasVerticalPosition;
      textObj1.left = canvasHorizontalPosition;
      textObj2.top = canvasVerticalPosition;
      textObj2.left = canvasHorizontalPosition;
    } else if (type === "title") {
      canvasVerticalPosition =
        canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
      textObj1.top = canvasVerticalPosition;
      textObj1.left = canvasHorizontalPosition;
      textObj2.top = canvasVerticalPosition;
      textObj2.left = canvasHorizontalPosition;
    }
    textObj.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: fabric.Canvas.originalHeight / 2,
    });
    textObj1.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: textObj.top + 35,
    });
    textObj2.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: textObj1.top + 35,
    });
    canvas.add(textObj, textObj1, textObj2);
    var selectedObjects = [textObj, textObj1, textObj2];
    this.displayCommonControls(selectedObjects);
    canvas.renderAll();

    if (canvas) {
      let frontCanvas = getCanvasBySide(this.props.allCanvasList, "front");
      let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
      removeStates();
      removeStates2();
      addToHistory(frontCanvas);
      addToHistory2(backCanvas);
    }
  };
  addSampleText3 = (type, isVerse) => {
    isVerse = !!isVerse;
    var canvas = this.props.canvas;
    const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
    const canvasHorizontalCenter = fabric.Canvas.originalWidth / 2;
    let top = 50;
    const textProperties = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE3_FONT_PROPS1.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE3_FONT_PROPS1.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#173ca0",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 120,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    const textProperties1 = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE3_FONT_PROPS2.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE3_FONT_PROPS2.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#5895cf",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 160,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    var HideControls = {
      mb: false,
      mt: false,
    };
    var textObj = new fabric.Textbox("CHEERS", textProperties);
    var textObj1 = new fabric.Textbox("TO THE HAPPY COUPLE", textProperties1);
    textObj.setControlsVisibility(HideControls);
    textObj1.setControlsVisibility(HideControls);
    canvas.setActiveObject(textObj);
    canvas.calcOffset();
    let canvasVerticalPosition;
    let canvasHorizontalPosition;
    if (type === "heading") {
      //set -25% from vertically center position
      canvasVerticalPosition =
        canvasVerticalCenter - (30 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.96 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
      textObj1.top = canvasVerticalPosition;
      textObj1.left = canvasHorizontalPosition;
    } else if (type === "description") {
      //add +15% from vertically center position
      canvasVerticalPosition =
        canvasVerticalCenter + (30 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.7 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
      textObj1.top = canvasVerticalPosition;
      textObj1.left = canvasHorizontalPosition;
    } else if (type === "title") {
      canvasVerticalPosition =
        canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
      textObj1.top = canvasVerticalPosition;
      textObj1.left = canvasHorizontalPosition;
    }
    textObj.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: fabric.Canvas.originalHeight / 2,
    });
    textObj1.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: textObj.top + 30,
    });
    canvas.add(textObj, textObj1);
    var selectedObjects = [textObj, textObj1];
    this.displayCommonControls(selectedObjects);
    canvas.renderAll();
    if (canvas) {
      let frontCanvas = getCanvasBySide(this.props.allCanvasList, "front");
      let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
      removeStates();
      removeStates2();
      addToHistory(frontCanvas);
      addToHistory2(backCanvas);
    }
  };
  addSampleText4 = (type, isVerse) => {
    isVerse = !!isVerse;
    var canvas = this.props.canvas;
    const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
    const canvasHorizontalCenter = fabric.Canvas.originalWidth / 2;
    let top = 50;
    const textProperties = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE4_FONT_PROPS1.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE4_FONT_PROPS1.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#00b3b6",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 140,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    const textProperties1 = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE4_FONT_PROPS2.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE4_FONT_PROPS2.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#304ca8",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 120,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    var HideControls = {
      mb: false,
      mt: false,
    };
    var textObj = new fabric.Textbox("Keep Being", textProperties);
    var textObj1 = new fabric.Textbox("AMAZING", textProperties1);
    textObj.setControlsVisibility(HideControls);
    textObj1.setControlsVisibility(HideControls);
    canvas.setActiveObject(textObj);
    canvas.calcOffset();
    let canvasVerticalPosition;
    let canvasHorizontalPosition;
    if (type === "heading") {
      //set -25% from vertically center position
      canvasVerticalPosition =
        canvasVerticalCenter - (30 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.96 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
      textObj1.top = canvasVerticalPosition;
      textObj1.left = canvasHorizontalPosition;
    } else if (type === "description") {
      //add +15% from vertically center position
      canvasVerticalPosition =
        canvasVerticalCenter + (30 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.7 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
      textObj1.top = canvasVerticalPosition;
      textObj1.left = canvasHorizontalPosition;
    } else if (type === "title") {
      canvasVerticalPosition =
        canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
      textObj1.top = canvasVerticalPosition;
      textObj1.left = canvasHorizontalPosition;
    }
    textObj.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: fabric.Canvas.originalHeight / 2,
    });
    textObj1.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: textObj.top + 40,
    });
    canvas.add(textObj, textObj1);
    var selectedObjects = [textObj, textObj1];
    this.displayCommonControls(selectedObjects);
    canvas.renderAll();
    if (canvas) {
      let frontCanvas = getCanvasBySide(this.props.allCanvasList, "front");
      let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
      removeStates();
      removeStates2();
      addToHistory(frontCanvas);
      addToHistory2(backCanvas);
    }
  };
  addSampleText5 = (type, isVerse) => {
    isVerse = !!isVerse;
    var canvas = this.props.canvas;
    const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
    const canvasHorizontalCenter = fabric.Canvas.originalWidth / 2;
    let top = 50;
    const textProperties = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE5_FONT_PROPS1.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE5_FONT_PROPS1.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#2c2e35",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 150,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    const textProperties1 = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE5_FONT_PROPS2.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE5_FONT_PROPS2.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#ff601f",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 150,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    var HideControls = {
      mb: false,
      mt: false,
    };
    var textObj = new fabric.Textbox("Life Is An", textProperties);
    var textObj1 = new fabric.Textbox("Adventure", textProperties1);
    textObj.setControlsVisibility(HideControls);
    textObj1.setControlsVisibility(HideControls);
    canvas.setActiveObject(textObj);
    canvas.calcOffset();
    let canvasVerticalPosition;
    let canvasHorizontalPosition;
    if (type === "heading") {
      //set -25% from vertically center position
      canvasVerticalPosition =
        canvasVerticalCenter - (30 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.96 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
      textObj1.top = canvasVerticalPosition;
      textObj1.left = canvasHorizontalPosition;
    } else if (type === "description") {
      //add +15% from vertically center position
      canvasVerticalPosition =
        canvasVerticalCenter + (30 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.7 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
      textObj1.top = canvasVerticalPosition;
      textObj1.left = canvasHorizontalPosition;
    } else if (type === "title") {
      canvasVerticalPosition =
        canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
      textObj1.top = canvasVerticalPosition;
      textObj1.left = canvasHorizontalPosition;
    }
    textObj.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: fabric.Canvas.originalHeight / 2,
    });
    textObj1.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: textObj.top + 32,
    });
    canvas.add(textObj, textObj1);
    var selectedObjects = [textObj, textObj1];
    this.displayCommonControls(selectedObjects);
    canvas.renderAll();
    if (canvas) {
      let frontCanvas = getCanvasBySide(this.props.allCanvasList, "front");
      let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
      removeStates();
      removeStates2();
      addToHistory(frontCanvas);
      addToHistory2(backCanvas);
    }
  };
  addSampleText6 = (type, isVerse) => {
    isVerse = !!isVerse;
    var canvas = this.props.canvas;
    const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
    const canvasHorizontalCenter = fabric.Canvas.originalWidth / 2;
    let top = 50;
    const textProperties = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE6_FONT_PROPS1.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE6_FONT_PROPS1.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#83d5b6",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 120,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    const textProperties1 = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE6_FONT_PROPS2.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE6_FONT_PROPS2.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#83d5b6",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 120,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    const textProperties2 = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE6_FONT_PROPS3.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE6_FONT_PROPS3.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#83d5b6",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 120,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    var HideControls = {
      mb: false,
      mt: false,
    };
    var textObj = new fabric.Textbox("Save", textProperties);
    var textObj1 = new fabric.Textbox("the", textProperties1);
    var textObj2 = new fabric.Textbox("Date", textProperties2);
    textObj.setControlsVisibility(HideControls);
    textObj1.setControlsVisibility(HideControls);
    textObj2.setControlsVisibility(HideControls);
    canvas.setActiveObject(textObj);
    canvas.calcOffset();
    let canvasVerticalPosition;
    let canvasHorizontalPosition;
    if (type === "heading") {
      //set -25% from vertically center position
      canvasVerticalPosition =
        canvasVerticalCenter - (30 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.96 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
    } else if (type === "description") {
      //add +15% from vertically center position
      canvasVerticalPosition =
        canvasVerticalCenter + (30 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.7 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
    } else if (type === "title") {
      canvasVerticalPosition =
        canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
    }
    textObj.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: fabric.Canvas.originalHeight / 2,
    });
    textObj1.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: textObj.top + 30,
    });
    textObj2.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: textObj1.top + 30,
    });
    canvas.add(textObj, textObj1, textObj2);
    var selectedObjects = [textObj, textObj1, textObj2];
    this.displayCommonControls(selectedObjects);
    canvas.renderAll();
    if (canvas) {
      let frontCanvas = getCanvasBySide(this.props.allCanvasList, "front");
      let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
      removeStates();
      removeStates2();
      addToHistory(frontCanvas);
      addToHistory2(backCanvas);
    }
  };
  addSampleText7 = (type, isVerse) => {
    isVerse = !!isVerse;
    var canvas = this.props.canvas;
    const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
    const canvasHorizontalCenter = fabric.Canvas.originalWidth / 2;
    let top = 50;
    const textProperties = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE7_FONT_PROPS1.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE7_FONT_PROPS1.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#5d6fba",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 180,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    const textProperties1 = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE7_FONT_PROPS2.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE7_FONT_PROPS2.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#3f487a",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 150,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    var HideControls = {
      mb: false,
      mt: false,
    };
    var textObj = new fabric.Textbox("MY FIRST", textProperties);
    var textObj1 = new fabric.Textbox("YEARS", textProperties1);
    textObj.setControlsVisibility(HideControls);
    textObj1.setControlsVisibility(HideControls);
    canvas.setActiveObject(textObj);
    canvas.calcOffset();
    let canvasVerticalPosition;
    let canvasHorizontalPosition;
    if (type === "heading") {
      //set -25% from vertically center position
      canvasVerticalPosition =
        canvasVerticalCenter - (30 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.96 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
      textObj1.top = canvasVerticalPosition;
      textObj1.left = canvasHorizontalPosition;
    } else if (type === "description") {
      //add +15% from vertically center position
      canvasVerticalPosition =
        canvasVerticalCenter + (30 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.7 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
      textObj1.top = canvasVerticalPosition;
      textObj1.left = canvasHorizontalPosition;
    } else if (type === "title") {
      canvasVerticalPosition =
        canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
      textObj1.top = canvasVerticalPosition;
      textObj1.left = canvasHorizontalPosition;
    }
    textObj.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: fabric.Canvas.originalHeight / 2,
    });
    textObj1.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: textObj.top + 40,
    });
    canvas.add(textObj, textObj1);
    var selectedObjects = [textObj, textObj1];
    this.displayCommonControls(selectedObjects);
    canvas.renderAll();
    if (canvas) {
      let frontCanvas = getCanvasBySide(this.props.allCanvasList, "front");
      let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
      removeStates();
      removeStates2();
      addToHistory(frontCanvas);
      addToHistory2(backCanvas);
    }
  };
  addSampleText8 = (type, isVerse) => {
    isVerse = !!isVerse;
    var canvas = this.props.canvas;
    const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
    const canvasHorizontalCenter = fabric.Canvas.originalWidth / 2;
    let top = 50;
    const textProperties = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE8_FONT_PROPS1.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE8_FONT_PROPS1.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#33c1c0",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 120,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    const textProperties1 = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE8_FONT_PROPS2.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE8_FONT_PROPS2.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#d12926",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 120,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    const textProperties2 = {
      type: "textbox",
      id: Math.floor(Math.random() * 1000),
      breakWords: true,
      fontFamily: SAMPLE8_FONT_PROPS3.font,
      left: fabric.Canvas.originalWidth * 0.05,
      top: top,
      fontSize: SAMPLE8_FONT_PROPS3.title_size,
      strokeWidth: 1,
      strokeLineJoin: "bevil",
      fill: "#5c6e19",
      subTargetCheck: true,
      transparentCorners: false,
      cornerColor: "#1babeb",
      cornerStrokeColor: "#1babeb",
      borderColor: "#1babeb",
      cornerSize: 5,
      padding: 5,
      cornerStyle: "circle",
      listStyle: "",
      lineHeight: 1,
      width: 120,
      textAlign: "center",
      selectionEnd: "",
      styles: {},
      isDefault: false,
      charSpacing: 1,
    };
    var HideControls = {
      mb: false,
      mt: false,
    };
    var textObj = new fabric.Textbox("PEACE", textProperties);
    var textObj1 = new fabric.Textbox("LOVE", textProperties1);
    var textObj2 = new fabric.Textbox("PETS", textProperties2);
    textObj.setControlsVisibility(HideControls);
    textObj1.setControlsVisibility(HideControls);
    textObj2.setControlsVisibility(HideControls);
    canvas.setActiveObject(textObj);
    canvas.calcOffset();
    let canvasVerticalPosition;
    let canvasHorizontalPosition;
    if (type === "heading") {
      //set -25% from vertically center position
      canvasVerticalPosition =
        canvasVerticalCenter - (30 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.96 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
    } else if (type === "description") {
      //add +15% from vertically center position
      canvasVerticalPosition =
        canvasVerticalCenter + (30 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.7 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
    } else if (type === "title") {
      canvasVerticalPosition =
        canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
      canvasHorizontalPosition =
        canvasHorizontalCenter - 0.5 * canvasHorizontalCenter;
      textObj.top = canvasVerticalPosition;
      textObj.left = canvasHorizontalPosition;
    }
    textObj.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: fabric.Canvas.originalHeight / 2,
    });
    textObj1.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: textObj.top + 45,
    });
    textObj2.set({
      originX: "center",
      originY: "center",
      left: fabric.Canvas.originalWidth / 2,
      top: textObj1.top + 45,
    });
    canvas.add(textObj, textObj1, textObj2);
    var selectedObjects = [textObj, textObj1, textObj2];
    this.displayCommonControls(selectedObjects);
    canvas.renderAll();
    if (canvas) {
      let frontCanvas = getCanvasBySide(this.props.allCanvasList, "front");
      let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
      removeStates();
      removeStates2();
      addToHistory(frontCanvas);
      addToHistory2(backCanvas);
    }
  };
  displayCommonControls(selectedObjects) {
    var canvas = this.props.canvas;
    // Check if more than one object is selected
    if (selectedObjects.length > 1) {
      var box = new fabric.ActiveSelection(selectedObjects, {
        canvas: canvas,
      });
      box.set({
        transparentCorners: false,
        cornerColor: "#1babeb",
        cornerStrokeColor: "#1babeb",
        borderColor: "#1babeb",
        cornerSize: 5,
        padding: 5,
        cornerStyle: "circle",
        width: 150,
        textAlign: "center",
      });
      box.setControlsVisibility({
        mb: false,
        mt: false,
      });
      canvas.setActiveObject(box);
      canvas.requestRenderAll();
    }
  }

  setCanvasFill = (bgcolor) => {
    var canvas = this.props.canvas;
    const rgbaColor = `rgba(${bgcolor.rgb.r}, ${bgcolor.rgb.g}, ${bgcolor.rgb.b}, ${bgcolor.rgb.a})`;
    deleteCanvasBg(this.props.canvas);
    canvas.backgroundColor = rgbaColor;
    canvas.renderAll();
    this.props.triggerState();
    if (canvas) {
      let frontCanvas = getCanvasBySide(this.props.allCanvasList, "front");
      let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
      removeStates();
      removeStates2();
      addToHistory(frontCanvas);
      addToHistory2(backCanvas);
    }
  };

  dynamicBGcolors = (bgcol) => {
    var bgcolArray = this.state.bgcolArray;
    bgcolArray.push(bgcol);
    bgcolArray = unique(bgcolArray);
    this.setState({
      bgcolArray: bgcolArray,
    });
    this.setState({
      backgroundcolor: bgcol,
    });
    localStorage.setItem("bgcolors", JSON.stringify(bgcolArray));
  };

  getImageTypeFromDataURL = (url) => {
    // Split the URL by "/"
    const urlParts = url.split("/");
    // Get the last part of the URL, which is the filename
    const filename = urlParts[urlParts.length - 1];
    // Split the filename by "." to get the file extension
    const filenameParts = filename.split(".");
    // Get the last part of the filenameParts, which is the file extension
    const fileExtension = filenameParts[filenameParts.length - 1];
    // Convert the file extension to lowercase (optional)
    const lowercaseFileExtension = fileExtension.toLowerCase();
    return lowercaseFileExtension;
  };

  addImageOnCanvas = async (src, id, imgName) => {
    let start = performance.now();
    this.props.isSpin(true);
    let imageUrlWithoutQueryParam = src.split("?")[0];
    let imageUrl = imageUrlWithoutQueryParam + "?v=" + Date.now();
    var imageType = this.getImageTypeFromDataURL(imageUrlWithoutQueryParam);
    var canvas = this.props.canvas;
    const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
    const self = this;
    let isImageLoaded = false;
    if (imageType === "svg") {
      fabric.loadSVGFromURL(imageUrl, function (objects, options) {
        var svgObj = fabric.util.groupSVGElements(objects, options);
        svgObj.src = imageUrl;
        svgObj.crossOrigin = "anonymous";
        svgObj.crossDomain = true;
        let canvasVerticalPosition;
        canvasVerticalPosition =
          canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
        svgObj.top = canvasVerticalPosition;

        var HideControls = {
          mb: false,
          mt: false,
        };
        svgObj.setControlsVisibility(HideControls);
        ///const imageWidth = svgObj.width;
        // if (imageWidth > IMAGE_MAX_WIDTH) {
        //     self.setState({
        //         uploadImageWidth: imageWidth,
        //         isLargeImage: true,
        //     });
        //     return false;
        // }
        //calculate image DPI
        //const canvasWidthInInch = parseFloat(self.props.templateSize.width);
        // const imageDpi = getDpi(imageWidth, canvasWidthInInch);
        // self.setState({
        //     imageDPI: imageDpi,
        // });
        // if (imageDpi < DPI_THRESHOLD) {
        //     self.setState({
        //         isDPIOpen: true,
        //     });
        // }
        // Set the position of the SVG object
        svgObj.set({
          type: "image",
          id: id,
          strokeWidth: "",
          strokeLineJoin: "bevil",
          subTargetCheck: true,
          transparentCorners: false,
          hasControls: true,
          hasBorders: true,
          cornerColor: "#1babeb",
          cornerStrokeColor: "#1babeb",
          borderColor: "#1babeb",
          cornerSize: 8,
          padding: 5,
          cornerStyle: "circle",
          originX: "center",
          originY: "center",
          left: fabric.Canvas.originalWidth / 2,
          isDefault: false,
          src: imageUrl,
          imgName: imgName,
        });
        self.props.setImages(svgObj);
        var scale;
        if (svgObj.width > fabric.Canvas.originalWidth) {
          scale = fabric.Canvas.originalWidth / 2 / svgObj.width;
          svgObj.set({ scaleX: scale, scaleY: scale });
        } else if (svgObj.height > fabric.Canvas.originalHeight) {
          scale = fabric.Canvas.originalHeight / 2 / svgObj.height;
          svgObj.set({ scaleY: scale, scaleX: scale });
        }
        // Adjust the position and size of the object to fit within the canvas
        svgObj.setCoords();
        // Add the SVG object to the canvas
        canvas.add(svgObj);
        canvas.setActiveObject(svgObj);
        // Render the canvas
        canvas.renderAll();
        setTimeout(() => {
          self.props.isSpin(false);
        }, performance.now() - start);
        if (canvas) {
          let frontCanvas = getCanvasBySide(self.props.allCanvasList, "front");
          let backCanvas = getCanvasBySide(self.props.allCanvasList, "back");
          removeStates();
          removeStates2();
          addToHistory(frontCanvas);
          addToHistory2(backCanvas);
        }
      });
    } else {
      var image = new Image();
      image.src = imageUrl;
      image.crossOrigin = "anonymous";
      image.crossDomain = true;
      image.onload = () => {
        if (isImageLoaded) {
          return;
        }
        isImageLoaded = true;
        var img = new fabric.Image(image);
        var HideControls = {
          mb: false,
          mt: false,
        };
        img.setControlsVisibility(HideControls);
        //const imageWidth = img.width;
        // if (imageWidth > IMAGE_MAX_WIDTH) {
        //     this.setState({
        //         uploadImageWidth: imageWidth,
        //         isLargeImage: true,
        //     });
        //     return false;
        // }
        //calculate image DPI
        //const canvasWidthInInch = parseFloat(this.props.templateSize.width);
        // const imageDpi = getDpi(imageWidth, canvasWidthInInch);
        // this.setState({
        //     imageDPI: imageDpi,
        // });
        // if (imageDpi < DPI_THRESHOLD) {
        //     this.setState({
        //         isDPIOpen: true,
        //     });
        // }
        let canvasVerticalPosition;
        canvasVerticalPosition =
          canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
        img.top = canvasVerticalPosition;
        img.set({
          type: "image",
          id: id,
          strokeWidth: "",
          strokeLineJoin: "bevil",
          fill: "#6c757d",
          subTargetCheck: true,
          transparentCorners: false,
          hasControls: true,
          hasBorders: true,
          cornerColor: "#1babeb",
          cornerStrokeColor: "#1babeb",
          borderColor: "#1babeb",
          cornerSize: 8,
          padding: 5,
          cornerStyle: "circle",
          originX: "center",
          originY: "center",
          left: fabric.Canvas.originalWidth / 2,
          isDefault: false,
          src: imageUrl,
          imgName: imgName,
          name: imgName,
        });
        this.props.setImages(img);
        var scale;
        if (img.width > fabric.Canvas.originalWidth) {
          scale = fabric.Canvas.originalWidth / 2 / img.width;
          img.set({ scaleX: scale, scaleY: scale });
        } else if (img.height > fabric.Canvas.originalHeight) {
          scale = fabric.Canvas.originalHeight / 2 / img.height;
          img.set({ scaleY: scale, scaleX: scale });
        }
        img.setCoords();
        canvas.add(img);
        canvas.setActiveObject(img);
        canvas.renderAll();
        setTimeout(() => {
          this.props.isSpin(false);
        }, performance.now() - start);
        if (canvas) {
          let frontCanvas = getCanvasBySide(this.props.allCanvasList, "front");
          let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
          removeStates();
          removeStates2();
          addToHistory(frontCanvas);
          addToHistory2(backCanvas);
        }
      };
    }
  };

  shapeUpload = async (event) => {
    if (event.target.files.length > 0) {
      const maxSizeInBytes = 30 * 1024 * 1024; // 30MB
      const apiDynamicUrlAddress = config.default.api1.host;
      const apiUrl = `${apiDynamicUrlAddress}/uploadImage`;
      const currentDate = new Date();
      // Format the date and time
      const year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const hours = String(currentDate.getHours()).padStart(2, "0");
      const minutes = String(currentDate.getMinutes()).padStart(2, "0");
      const seconds = String(currentDate.getSeconds()).padStart(2, "0");
      // Create the formatted date and time string
      const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      const dataImage = [];
      const validFiles = [];
      // Check file size before creating FileReader promises
      Array.from(event.target.files).forEach((file) => {
        if (file.size < maxSizeInBytes) {
          validFiles.push(file);
        } else {
          toast.error(`${file.name} is too large. Maximum size is 30MB.`, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
      // Use a Promise to wait for all FileReader tasks to complete
      const readerPromises = validFiles.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();

          reader.onload = (e) => {
            const img = new Image();
            img.onload = () => {
              const minInches = 18;
              const minDPI = 100;
              const minPixels = minInches * minDPI;
              const imgWidth = img.width;
              const imgHeight = img.height;

              // Calculate DPI for width and height
              const dpiX = imgWidth / minInches;
              const dpiY = imgHeight / minInches;

              if (
                imgWidth <= minPixels ||
                imgHeight <= minPixels ||
                dpiX <= minDPI ||
                dpiY <= minDPI
              ) {
                toast.error(
                  "Image is too small. Please upload an image with at least 18 inches and 100 DPI.",
                  { position: toast.POSITION.TOP_RIGHT }
                );
                resolve(); // Exit early if the image doesn't meet requirements
                return;
              }

              const imageData = {
                fileName: file.name,
                file: e.target.result,
              };
              const previewData = {
                fileName: file.name,
                file: e.target.result,
                isLoading: true,
              };
              dataImage.push(imageData);
              this.props.previewLoaderImage("", previewData);
              resolve(); // Resolve the Promise when the FileReader task is done
            };
            img.src = e.target.result; // Set image source to trigger the onload event
          };
          reader.readAsDataURL(file);
        });
      });
      // Wait for all FileReader tasks to complete before making the API call
      Promise.all(readerPromises)
        .then(() => {
          const query = new URLSearchParams(window.location.search);
          const userId = query.get("u");
          let payload;
          if (userId) {
            payload = {
              date: formattedDate,
              userId: userId,
              role: this.props.isAdmin ? "admin" : "user",
              images: dataImage,
            };
          } else {
            payload = {
              date: formattedDate,
              images: dataImage,
            };
          }
          axios
            .post(apiUrl, payload)
            .then((response) => {
              if (response.data.code === 200) {
                response.data.data.forEach((imageData) => {
                  let imageUrl = imageData.ImagePath;
                  this.addImageOnCanvas(
                    imageUrl,
                    imageData.id,
                    imageData.fileName
                  );
                  this.props.imageId.push(imageData.id);
                  this.props.previewLoaderImage("response", imageData);
                });
                if (!userId) {
                  this.props.imgGallery(this.props.imageId);
                } else {
                  this.props.imgGallery();
                }
                window.innerWidth <= 640 && this.props.mobiletoggleSidebar();
                toast.success("Image Uploaded Successfully", {
                  position: toast.POSITION.TOP_RIGHT,
                });
              } else {
                this.props.closepreviewLoader();
                toast.error(response.data.Message, {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            })
            .catch((error) => console.log(error));
        })
        .catch((error) => console.log(error));
    }
    document.getElementById("gallery-upload").value = "";
  };

  deleteImage = (id) => {
    const query = new URLSearchParams(window.location.search);
    const userId = query.get("u");
    const apiDynamicUrlAddress = config.default.api1.host;
    const apiUrl = `${apiDynamicUrlAddress}/deleteGalleryImage?id=${id}`;
    axios
      .delete(apiUrl)
      .then((response) => {
        if (response.status === 200) {
          if (!userId) {
            const indexToDelete = this.props.imageId.findIndex(
              (image) => image === id
            );
            const indexToDelete1 = this.props?.previewLoader.findIndex(
              (image) => image.id === id
            );
            // Check if the image with the given id was found
            if (indexToDelete !== -1) {
              // Use splice to remove the image from the array
              this.props.imageId.splice(indexToDelete, 1);
              this.props.imgGallery(this.props.imageId);
              toast.success("Image Deleted Successfully", {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
            if (indexToDelete1 !== -1) {
              this.props.previewLoader.splice(indexToDelete1, 1);
            }
          } else {
            toast.success("Image Deleted Successfully", {
              position: toast.POSITION.TOP_RIGHT,
            });
            this.props.imgGallery();
          }
        } else {
          toast.error("Images Delete Failed", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch((error) => console.log(error));
  };

  fillImage = (flg) => {
    var canvas = this.props.canvas;
    var shape;
    const canvasVerticalCenter = fabric.Canvas.originalHeight / 2;
    let canvasVerticalPosition;
    canvasVerticalPosition =
      canvasVerticalCenter + (1 / 100) * canvasVerticalCenter;
    if (flg === 1) {
      shape = new fabric.Circle({
        type: "circle",
        id: Math.floor(Math.random() * 1000),
        radius: 70,
        left: fabric.Canvas.originalWidth / 2,
        top: canvasVerticalPosition,
        originX: "center",
        originY: "center",
        width: 140,
        height: 140,
        strokeWidth: "",
        stroke: "",
        fill: "#000",
        subTargetCheck: true,
        transparentCorners: false,
        cornerColor: "#1babeb",
        cornerStrokeColor: "#1babeb",
        borderColor: "#1babeb",
        cornerSize: 10,
        padding: 5,
        cornerStyle: "circle",
        isDefault: false,
      });
    } else if (flg === 2) {
      shape = new fabric.Rect({
        type: "rect",
        id: Math.floor(Math.random() * 1000),
        left: fabric.Canvas.originalWidth / 2,
        top: canvasVerticalPosition,
        originX: "center",
        originY: "center",
        width: 200,
        height: 200,
        fill: "#000",
        subTargetCheck: true,
        transparentCorners: false,
        cornerColor: "#1babeb",
        cornerStrokeColor: "#1babeb",
        borderColor: "#1babeb",
        cornerSize: 10,
        padding: 5,
        cornerStyle: "circle",
        isDefault: false,
      });
    } else if (flg === 3) {
      shape = new fabric.Polygon([
        { x: 100, y: 0 },
        { x: 116, y: 46 },
        { x: 183, y: 53 },
        { x: 132, y: 87 },
        { x: 150, y: 150 },
        { x: 100, y: 120 },
        { x: 50, y: 150 },
        { x: 68, y: 87 },
        { x: 17, y: 53 },
        { x: 84, y: 46 },
      ]);
      // Customize hexagon properties (e.g., fill color, stroke color)
      shape.set({
        layerType: "star",
        type: "polygon",
        id: Math.floor(Math.random() * 1000),
        radius: 70,
        left: fabric.Canvas.originalWidth / 2,
        top: canvasVerticalPosition,
        originX: "center",
        originY: "center",
        width: 150,
        height: 150,
        strokeWidth: "",
        stroke: "",
        fill: "#000",
        subTargetCheck: true,
        transparentCorners: false,
        cornerColor: "#1babeb",
        cornerStrokeColor: "#1babeb",
        borderColor: "#1babeb",
        cornerSize: 10,
        padding: 5,
        cornerStyle: "circle",
        isDefault: false,
      });
    } else if (flg === 4) {
      const hexagonWidth = 120; // Width of the hexagon
      const hexagonHeight = 140; // Manually set height for the hexagon

      const centerX = 150; // X-coordinate of the center
      const centerY = 100; // Y-coordinate of the center

      // Calculate the offsets for the top and bottom vertices
      const verticalOffset = hexagonHeight / 2;
      const horizontalOffset = hexagonWidth / 2;
      shape = new fabric.Polygon([
        { x: centerX, y: centerY - verticalOffset }, // Top vertex
        { x: centerX + horizontalOffset, y: centerY - verticalOffset / 2 }, // Top-right
        { x: centerX + horizontalOffset, y: centerY + verticalOffset / 2 }, // Bottom-right
        { x: centerX, y: centerY + verticalOffset }, // Bottom vertex
        { x: centerX - horizontalOffset, y: centerY + verticalOffset / 2 }, // Bottom-left
        { x: centerX - horizontalOffset, y: centerY - verticalOffset / 2 }, // Top-left
      ]);
      // Customize hexagon properties (e.g., fill color, stroke color)
      shape.set({
        layerType: "hexagon",
        type: "polygon",
        id: Math.floor(Math.random() * 1000),
        radius: 70,
        left: fabric.Canvas.originalWidth / 2,
        top: canvasVerticalPosition,
        originX: "center",
        originY: "center",
        width: 150,
        height: 150,
        strokeWidth: "",
        stroke: "",
        fill: "#000",
        subTargetCheck: true,
        transparentCorners: false,
        cornerColor: "#1babeb",
        cornerStrokeColor: "#1babeb",
        borderColor: "#1babeb",
        cornerSize: 10,
        padding: 5,
        cornerStyle: "circle",
        isDefault: false,
      });
    } else if (flg === 5) {
      shape = new fabric.Polygon([
        { x: 100, y: 100 },
        { x: 200, y: 100 },
        { x: 150, y: 0 },
      ]);
      // Customize triangle properties (e.g., fill color, stroke color)
      shape.set({
        layerType: "triangle",
        type: "polygon",
        id: Math.floor(Math.random() * 1000),
        radius: 70,
        left: fabric.Canvas.originalWidth / 2,
        top: canvasVerticalPosition,
        originX: "center",
        originY: "center",
        width: 100,
        height: 100,
        strokeWidth: "",
        stroke: "",
        fill: "#000",
        subTargetCheck: true,
        transparentCorners: false,
        cornerColor: "#1babeb",
        cornerStrokeColor: "#1babeb",
        borderColor: "#1babeb",
        cornerSize: 10,
        padding: 5,
        cornerStyle: "circle",
        isDefault: false,
      });
    } else if (flg === 6) {
      shape = new fabric.Polygon([
        { x: 100, y: 0 }, // Top point
        { x: 175, y: 50 }, // Right upper side
        { x: 145, y: 130 }, // Bottom right side
        { x: 55, y: 130 }, // Bottom left side
        { x: 25, y: 50 }, // Left upper side
      ]);
      // Customize pentagon properties (e.g., fill color, stroke color)
      shape.set({
        layerType: "pentagon",
        type: "polygon",
        id: Math.floor(Math.random() * 1000),
        radius: 70,
        left: fabric.Canvas.originalWidth / 2,
        top: canvasVerticalPosition,
        originX: "center",
        originY: "center",
        width: 200,
        height: 200,
        strokeWidth: "",
        stroke: "",
        fill: "#000",
        subTargetCheck: true,
        transparentCorners: false,
        cornerColor: "#1babeb",
        cornerStrokeColor: "#1babeb",
        borderColor: "#1babeb",
        cornerSize: 10,
        padding: 5,
        cornerStyle: "circle",
        isDefault: false,
      });
    }
    canvas.add(shape);
    canvas.setActiveObject(shape);
    canvas.renderAll();
    if (canvas) {
      let frontCanvas = getCanvasBySide(this.props.allCanvasList, "front");
      let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
      removeStates();
      removeStates2();
      addToHistory(frontCanvas);
      addToHistory2(backCanvas);
    }
  };

  bgcolorClose = () => {
    this.setState({
      displaybgColorPicker: false,
    });
    this.dynamicBGcolors(this.props.backgroundColor);
  };

  setGradient1BGcolor = (color) => {
    this.setState({
      grad1color: color.hex,
    });
  };

  setGradient2BGcolor = (color) => {
    this.setState({
      grad2color: color.hex,
    });
  };

  setGradientBGcolor = (colone, coltwo, type) => {
    var canvas = this.props.canvas;
    deleteCanvasBg(this.props.canvas);
    this.props.triggerState();
    if (type === "vertical") {
      var verticalgrad = new fabric.Gradient({
        type: "linear",
        coords: {
          x1: 0,
          y1: canvas?.height / 4,
          x2: 0,
          y2: canvas?.height / 2 + canvas?.height / 4,
        },
        colorStops: [
          {
            color: colone,
            offset: 0,
          },
          {
            color: coltwo,
            offset: 1,
          },
        ],
      });
      if (canvas) {
        canvas.backgroundColor = verticalgrad;
        canvas.renderAll();
      }
    }
    if (type === "radial") {
      var radialgrad = new fabric.Gradient({
        type: "radial",
        coords: {
          r1: canvas?.width / 2,
          r2: canvas?.width / 4,
          x1: canvas?.width / 2 - 1,
          y1: canvas?.height / 2 - 1,
          x2: canvas?.width / 2,
          y2: canvas?.height / 2,
        },
        colorStops: [
          {
            color: colone,
            offset: 0,
          },
          {
            color: coltwo,
            offset: 1,
          },
        ],
      });
      if (canvas) {
        canvas.backgroundColor = radialgrad;
        canvas.renderAll();
      }
    }
    if (type === "horizontal") {
      var horizontalgrad = new fabric.Gradient({
        type: "linear",
        coords: {
          x1: canvas?.width / 4,
          y1: 0,
          x2: canvas?.width / 2 + canvas?.width / 4,
          y2: 0,
        },
        colorStops: [
          {
            color: colone,
            offset: 0,
          },
          {
            color: coltwo,
            offset: 1,
          },
        ],
      });
      if (canvas) {
        canvas.backgroundColor = horizontalgrad;
        canvas.renderAll();
      }
    }

    let frontCanvas = getCanvasBySide(this.props.allCanvasList, "front");

    let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");

    removeStates();
    removeStates2();
    addToHistory(frontCanvas);
    addToHistory2(backCanvas);
  };

  setBGcolor = (color) => {
    deleteCanvasBg(this.props.canvas);
    this.props.triggerState();
    var canvas = this.props.canvas;
    if (canvas) {
      canvas.backgroundColor = color;
      canvas.renderAll();
      let frontCanvas = getCanvasBySide(this.props.allCanvasList, "front");

      let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
      removeStates();
      removeStates2();
      addToHistory(frontCanvas);
      addToHistory2(backCanvas);
    }
  };

  incermentpage = () => {
    this.setState(
      {
        pagenum: this.state.pagenum + 1,
      },
      () => {}
    );
  };

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  setActiveTab(activeTabKey) {
    this.props.setActiveTab(activeTabKey);
  }

  colorCategory = (val) => {
    if (val === "standard") {
      this.setState({
        colorCat: "standard",
      });
    }
    if (val === "recommend") {
      this.setState({
        colorCat: "recommend",
      });
    }
    if (val === "custom") {
      this.setState({
        colorCat: "custom",
      });
    }
  };

  handleDrop = (e) => {
    e.preventDefault();
    const maxSizeInBytes = 30 * 1024 * 1024; // 30MB
    const apiDynamicUrlAddress = config.default.api1.host;
    const apiUrl = `${apiDynamicUrlAddress}/uploadImage`;
    const currentDate = new Date();

    // Format the date and time
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, "0");
    const day = String(currentDate.getDate()).padStart(2, "0");
    const hours = String(currentDate.getHours()).padStart(2, "0");
    const minutes = String(currentDate.getMinutes()).padStart(2, "0");
    const seconds = String(currentDate.getSeconds()).padStart(2, "0");

    // Create the formatted date and time string
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    const dataImage = [];
    const validFiles = [];
    // Check file size before creating FileReader promises
    Array.from(e.target.files).forEach((file) => {
      if (file.size < maxSizeInBytes) {
        validFiles.push(file);
      } else {
        toast.error(`${file.name} is too large. Maximum size is 30MB.`, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    });
    // Use a Promise to wait for all FileReader tasks to complete
    const readerPromises = validFiles.map((file) => {
      return new Promise((resolve) => {
        const reader = new FileReader();

        reader.onload = (e) => {
          const imageData = {
            fileName: file.name,
            file: e.target.result,
          };
          const previewData = {
            fileName: file.name,
            file: e.target.result,
            isLoading: true,
          };
          dataImage.push(imageData);
          this.props.previewLoaderImage("", previewData);
          resolve(); // Resolve the Promise when the FileReader task is done
        };

        reader.readAsDataURL(file);
      });
    });

    // Wait for all FileReader tasks to complete before making the API call
    Promise.all(readerPromises)
      .then(() => {
        const query = new URLSearchParams(window.location.search);
        const userId = query.get("u");
        let payload;
        if (userId) {
          payload = {
            date: formattedDate,
            userId: userId,
            role: this.props.isAdmin ? "admin" : "user",
            images: dataImage,
          };
        } else {
          payload = {
            date: formattedDate,
            images: dataImage,
          };
        }
        axios
          .post(apiUrl, payload)
          .then((response) => {
            if (response.data.code === 200) {
              response.data.data.forEach((imageData) => {
                let imageUrl = imageData.ImagePath;
                this.addImageOnCanvas(
                  imageUrl,
                  imageData.id,
                  imageData.fileName
                );
                this.props.imageId.push(imageData.id);
                this.props.previewLoaderImage("response", imageData);
              });
              if (!userId) {
                this.props.imgGallery(this.props.imageId);
              } else {
                this.props.imgGallery();
              }
              toast.success("Image Uploaded Successfully", {
                position: toast.POSITION.TOP_RIGHT,
              });
            } else {
              this.props.closepreviewLoader();
              toast.error(response.data.Message, {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          })
          .catch((error) => console.log(error));
      })
      .catch((error) => console.log(error));
  };

  handleDragOver = (e) => {
    e.preventDefault();
  };

  handleDragEnd = (result) => {
    if (!result.destination) return;
    this.props.draglayer(result);
  };

  layerActive = (obj) => {
    if (this.props.canvas) {
      this.props.canvas.setActiveObject(obj);
      this.props.canvas.renderAll();
    }
  };
  showInstruction = () => {
    this.setState({
      showIns: true,
    });
  };
  closeIns = () => {
    this.setState({
      showIns: false,
    });
  };
  render() {
    const { colorCat, systemType } = this.state;
    const activeTab = this.props.activeTab;
    const { selectedCategory, clipartCategories } = this.state;
    const selectedCategoryObj = clipartCategories?.find(
      (category) => category.value === selectedCategory
    );
    const { sortedArray, canvas } = this.props;
    let loaderActive = true;
    if (this.props.selectedImages.length > 0) {
      loaderActive = false;
    }
    return (
      <>
        <div
          className="side-panel-container largedevice"
          style={{ height: "auto", marginTop: "36px", width: "300px" }}
        >
          <TabPanel tabId="vertical-tab-one">
            <Container className="text-editer text-gallery">
              <Row className="img-title">
                <Col
                  sm="12"
                  className={`gallery-title d-flex justify-content-center`}
                  style={{
                    height: "50px",
                    paddingTop: "5%",
                    fontSize: "14px",
                    color: "#697582",
                    maxWidth: "max-content",
                  }}
                >
                  Text
                </Col>
                <Tooltip content="Instruction" direction="up">
                  <Col>
                    <BsFillInfoCircleFill
                      onClick={this.showInstruction}
                      style={{ cursor: "pointer" }}
                    />
                  </Col>
                </Tooltip>
                <Col
                  style={{
                    maxWidth: "max-content",
                    width: "auto",
                  }}
                >
                  <i
                    className="fa fa-times"
                    onClick={() =>
                      this.props.toggleSidebar(!this.props.collapse)
                    }
                  ></i>
                </Col>
              </Row>
              <Row>
                <Col
                  sm="12"
                  style={{ backgroundColor: "#fff", overflow: "hidden" }}
                >
                  <div
                    className="dcl-upload-button"
                    onClick={() => {
                      this.addText("title", "Type text here", false);
                    }}
                  >
                    {" "}
                    <label className=" dcl-button dcl-button--upload dcl-button--full-width">
                      Add Text
                    </label>
                  </div>
                  <div className="sample1-text">
                    <div
                      onClick={() => {
                        this.addSampleText1("title", "", false);
                      }}
                    >
                      <p className="sample-text-p1">besties</p>
                      <p className="sample-text-p2">FOREVER</p>
                    </div>
                    <div
                      style={{ marginTop: "-8px" }}
                      onClick={() => {
                        this.addSampleText2("title", "", false);
                      }}
                    >
                      <p className="sample1-text-p1">EAT</p>
                      <p className="sample1-text-p2">SLEEP</p>
                      <p className="sample1-text-p3">REPEAT</p>
                    </div>
                  </div>
                  <div className="sample2-text">
                    <div
                      onClick={() => {
                        this.addSampleText3("title", "", false);
                      }}
                    >
                      <p className="sample2-text-p1">CHEERS</p>
                      <p className="sample2-text-p2">TO THE HAPPY COUPLE</p>
                    </div>
                    <div
                      onClick={() => {
                        this.addSampleText4("title", "", false);
                      }}
                    >
                      <p className="sample3-text-p1">Keep Being</p>
                      <p className="sample3-text-p2">AMAZING</p>
                    </div>
                  </div>
                  <div className="sample3-text">
                    <div
                      onClick={() => {
                        this.addSampleText5("title", "", false);
                      }}
                    >
                      <p className="sample4-text-p1">Life Is An</p>
                      <p className="sample4-text-p2">Adventure</p>
                    </div>
                    <div
                      onClick={() => {
                        this.addSampleText6("title", "", false);
                      }}
                    >
                      <p className="sample5-text-p1">Save</p>
                      <p className="sample5-text-p2">the</p>
                      <p className="sample5-text-p3">Date</p>
                    </div>
                  </div>
                  <div class="sample4-text">
                    <div
                      onClick={() => {
                        this.addSampleText7("title", "", false);
                      }}
                    >
                      <p className="sample6-text-p1">MY FIRST</p>
                      <p className="sample6-text-p2">YEARS</p>
                    </div>
                    <div
                      onClick={() => {
                        this.addSampleText8("title", "", false);
                      }}
                    >
                      <p className="sample7-text-p1">PEACE</p>
                      <p className="sample7-text-p2">LOVE</p>
                      <p className="sample7-text-p3">PETS</p>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </TabPanel>
          <TabPanel tabId="vertical-tab-three" style={{}}>
            <Container
              className={
                this.state.IsLoaderClipart
                  ? "text-editer clip-gallery clipart-spinner-gallery"
                  : "text-editer clip-gallery"
              }
            >
              <Row className="img-title">
                <Col
                  sm="12"
                  className={`gallery-title d-flex justify-content-center`}
                  style={{
                    height: "50px",
                    paddingTop: "5%",
                    fontSize: "14px",
                    color: "#697582",
                    maxWidth: "max-content",
                  }}
                >
                  Clip&nbsp;Art
                </Col>
                <Tooltip content="Instruction" direction="up">
                  <Col>
                    <BsFillInfoCircleFill
                      onClick={this.showInstruction}
                      style={{ cursor: "pointer" }}
                    />
                  </Col>
                </Tooltip>
                <Col
                  style={{
                    maxWidth: "max-content",
                    width: "auto",
                  }}
                >
                  <i
                    className="fa fa-times"
                    onClick={() =>
                      this.props.toggleSidebar(!this.props.collapse)
                    }
                  ></i>
                </Col>
              </Row>
              <TabContent activeTab={this.state.imgactiveTab}>
                <TabPane tabId="1">
                  <Row
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    <Col
                      sm="12"
                      className={
                        this.state.IsLoaderClipart ? "clipart-spinner" : ""
                      }
                    >
                      {selectedCategoryObj && !this.state.IsLoaderClipart ? (
                        <>
                          <div className="dcl-upload-button">
                            <input
                              type="text"
                              placeholder="Search"
                              value={this.props.searchQuery}
                              onChange={this.props.handleSearchQueryChange}
                            />
                            <i className="fa fa-search" aria-hidden="true"></i>
                          </div>
                          <div className="dcl-browse-button">
                            <label>Browse by category</label>
                            <Select
                              className="clipart-dropdown"
                              value={selectedCategoryObj}
                              onChange={this.handleCategorySelect}
                              options={clipartCategories}
                              styles={{
                                option: optionStyles, // Apply the custom styles to the options
                              }}
                            />
                          </div>
                          <div
                            className="clipimage-container-outer"
                            style={{
                              overflow:
                                selectedCategoryObj.images.filter((image) =>
                                  image.name
                                    .toLowerCase()
                                    .includes(
                                      this.props.searchQuery.toLowerCase()
                                    )
                                ).length < 4 &&
                                this.props.searchQuery !== "" &&
                                "hidden",
                            }}
                          >
                            {selectedCategoryObj.images
                              .filter((image) =>
                                image.name
                                  .toLowerCase()
                                  .includes(
                                    this.props.searchQuery.toLowerCase()
                                  )
                              )
                              .map((image, i) => (
                                <div className="clipimage-container" key={i}>
                                  <div className="clipimage-container-inner">
                                    <img
                                      key={image.name}
                                      src={image.url}
                                      alt={image.name}
                                      className="clipart-img"
                                    />
                                    <i
                                      className="fa fa-plus add-clipart"
                                      onClick={() =>
                                        this.addImageOnCanvas(
                                          image.url,
                                          "",
                                          image.name
                                        )
                                      }
                                    ></i>
                                  </div>
                                </div>
                              ))}
                          </div>
                          {selectedCategoryObj.images.filter((image) =>
                            image.name
                              .toLowerCase()
                              .includes(this.props.searchQuery.toLowerCase())
                          ).length === 0 &&
                            this.props.searchQuery !== "" && (
                              <div className="clipart-empty">
                                No Clipart Images found
                              </div>
                            )}
                        </>
                      ) : this.state.IsLoaderClipart ? (
                        <>
                          <Spin />
                        </>
                      ) : (
                        <>
                          <div className="clip-gallery-no-image-icon">
                            <LuImageOff />
                          </div>
                          <p className="clip-gallery-no-image-text">
                            No Clipart Images found
                          </p>
                        </>
                      )}
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId="2">
                  <Row>
                    <Col sm="12">
                      <div className="pixabaysection">
                        <Form className="searchbar">
                          <Input
                            type="text"
                            onKeyPress={(event) =>
                              this.searchUnsplashimg(event)
                            }
                            placeholder="Search Images"
                          />
                        </Form>
                        <div
                          ref={this.imgScrollRef}
                          className="scroller"
                          id="scroll-1"
                        >
                          {this.state.unsplashImg?.map((photo, index) => {
                            return (
                              <span
                                className="image-wrapper"
                                key={index}
                                onClick={() =>
                                  this.addImageOnCanvas(photo.urls.regular)
                                }
                              >
                                <img
                                  className="pixabay"
                                  src={photo.urls.regular}
                                  alt=""
                                />
                              </span>
                            );
                          })}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Container>
          </TabPanel>
          <TabPanel tabId="vertical-tab-five">
            <Container
              className="text-editer image-gallery"
              style={{
                overflowY:
                  this.props?.selectedImages.length > 4 ||
                  this.props?.previewLoader.length > 6 ||
                  (this.props?.selectedImages.length >= 4 &&
                    this.props?.previewLoader.length >= 1)
                    ? "scroll"
                    : "",
              }}
            >
              {/* <Nav tabs> */}
              <Row className="img-title">
                <Col
                  sm="12"
                  className={`gallery-title d-flex justify-content-center`}
                  style={{
                    height: "50px",
                    paddingTop: "6%",
                    fontSize: "14px",
                    color: "#697582",
                    maxWidth: "max-content",
                  }}
                >
                  Image Gallery
                </Col>
                <Tooltip content="Instruction" direction="up">
                  <Col>
                    <BsFillInfoCircleFill
                      onClick={this.showInstruction}
                      style={{ cursor: "pointer" }}
                    />
                  </Col>
                </Tooltip>
                <Col
                  style={{
                    maxWidth: "max-content",
                    width: "auto",
                  }}
                >
                  <i
                    className="fa fa-times"
                    onClick={() =>
                      this.props.toggleSidebar(!this.props.collapse)
                    }
                  ></i>
                </Col>
              </Row>
              <TabContent activeTab={this.state.imgactiveTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col sm="12" style={{ backgroundColor: "#fff" }}>
                      <div
                        className="dcl-upload-button"
                        onDrop={this.handleDrop}
                        onDragOver={this.handleDragOver}
                      >
                        {" "}
                        <label
                          htmlFor="gallery-upload"
                          className=" dcl-button dcl-button--upload dcl-button--full-width"
                        >
                          Upload Image
                        </label>
                        <form>
                          <input
                            id="gallery-upload"
                            type="file"
                            onChange={this.shapeUpload}
                            accept=".jpg, .jpeg, .png, .gif"
                          />
                        </form>
                      </div>
                    </Col>
                  </Row>
                  {this.props.selectedImages &&
                  this.props.selectedImages.length > 0 ? (
                    <>
                      <Row>
                        <Col sm="12">
                          <div className="uploads-title">
                            Uploads ({this.props.selectedImages.length})
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm="12">
                          <div className="image-preview">
                            {this.props.selectedImages.map((image, index) => {
                              const imageObject =
                                this.props.canvas._objects.find(
                                  (obj) => obj.id === image.id
                                );
                              const isUsed = imageObject !== undefined;
                              return (
                                <div className="image-container" key={index}>
                                  <div
                                    className={`used-image-outer ${
                                      isUsed ? "used" : ""
                                    }`}
                                  >
                                    {isUsed && (
                                      <span className="used-image">Used</span>
                                    )}
                                  </div>
                                  <div>
                                    <img
                                      className={
                                        this.props.selectedImages.length > 4
                                          ? "image-upload-gallery-fox"
                                          : "image-upload-gallery"
                                      }
                                      key={index}
                                      src={image.image_url}
                                      alt={`Selected Preview ${index + 1}`}
                                    />
                                  </div>
                                  <div className="icon-outer">
                                    <i
                                      onClick={() => this.deleteImage(image.id)}
                                      className="fa fa-trash"
                                      aria-hidden="true"
                                    ></i>
                                    <i
                                      className="fa fa-plus"
                                      onClick={() =>
                                        this.addImageOnCanvas(
                                          image.image_url,
                                          image.id,
                                          image.fileName
                                        )
                                      }
                                    ></i>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <Row>
                      {this.props?.previewLoader.length === 0 && (
                        <>
                          <p className="img-note">
                            NOTE : Image size must be less than 30MB
                          </p>
                          <p className="img-text">
                            Click and drag an image from your gallery to the
                            design area. Supported file type (.jpg, .jpeg, .png,
                            .gif)
                          </p>
                          <div className="img-upload">
                            <i className="fas fa-image fa-2x text-muted "></i>
                          </div>
                        </>
                      )}
                    </Row>
                  )}
                  <div
                    className={
                      loaderActive
                        ? "loader-image-preview loader-active"
                        : "loader-image-preview"
                    }
                  >
                    {this.props?.previewLoader.map((image, index) => {
                      return (
                        <div className="loader-image-container" key={index}>
                          {image.isLoading && (
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </TabPane>
              </TabContent>
              {/* <Modal
                                show={
                                    this.state.isDPIOpen ||
                                    this.state.isLargeImage
                                }
                                onHide={this.closeDPIModal}
                                size="default"
                                id="lowDPI"
                                backdrop="static"
                                keyboard={false}
                            >
                                <Modal.Header>
                                    <Modal.Title>
                                        {this.state.isDPIOpen ? (
                                            <span style={{ fontSize: "24px" }}>
                                                Low DPI!
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                        {this.state.isLargeImage ? (
                                            <span style={{ fontSize: "24px" }}>
                                                Large Image
                                            </span>
                                        ) : (
                                            ""
                                        )}
                                    </Modal.Title>
                                    <div
                                        className=""
                                        style={{
                                            position: "absolute",
                                            marginLeft: "60%",
                                        }}
                                    >
                                        &nbsp;
                                    </div>{" "}
                                    <span
                                        title="close"
                                        style={{ cursor: "pointer" }}
                                        onClick={this.closeDPIModal}
                                    >
                                        {" "}
                                        X
                                    </span>
                                </Modal.Header>
                                <Modal.Body>
                                    {this.state.isDPIOpen ? (
                                        <p>
                                            <span>
                                                DPI of an uploaded image is{" "}
                                                <strong>
                                                    {this.state.imageDPI}
                                                </strong>
                                            </span>
                                            <span className="text-danger d-inline-block mt-2">
                                                <strong>Warning:</strong> It is
                                                recommended to upload an image
                                                with minimum{" "}
                                                <strong>{DPI_THRESHOLD}</strong>{" "}
                                                DPI
                                            </span>
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                    {this.state.isLargeImage ? (
                                        <p>
                                            <span>
                                                Width of uploaded image is{" "}
                                                <strong>
                                                    {
                                                        this.state
                                                            .uploadImageWidth
                                                    }
                                                </strong>{" "}
                                                pixels
                                            </span>
                                            <span className="text-danger d-inline-block mt-2">
                                                <strong>Error:</strong> Image
                                                width must be less than{" "}
                                                <strong>
                                                    {IMAGE_MAX_WIDTH}
                                                </strong>{" "}
                                                pixels
                                            </span>
                                        </p>
                                    ) : (
                                        ""
                                    )}
                                </Modal.Body>
                                <Modal.Footer
                                    style={{ justifyContent: "center" }}
                                >
                                    <button
                                        type="button"
                                        id="closeDPI"
                                        className={`btn btn-primary`}
                                        onClick={this.closeDPIModal}
                                    >
                                        Ok
                                    </button>
                                </Modal.Footer>
                            </Modal> */}
            </Container>
          </TabPanel>
          <TabPanel tabId="vertical-tab-eight">
            <Container className="color-gallery">
              <Row className="img-title color-title">
                <Col
                  sm="12"
                  className={`gallery-title d-flex justify-content-center`}
                  style={{
                    height: "50px",
                    paddingTop: "5%",
                    fontSize: "14px",
                    color: "#697582",
                    maxWidth: "max-content",
                  }}
                >
                  Add Background Color
                </Col>
                <Tooltip content="Instruction" direction="up">
                  <Col>
                    <BsFillInfoCircleFill
                      onClick={this.showInstruction}
                      style={{ cursor: "pointer" }}
                    />
                  </Col>
                </Tooltip>
                <Col
                  style={{
                    maxWidth: "max-content",
                    width: "auto",
                  }}
                >
                  <i
                    className="fa fa-times"
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      this.props.toggleSidebar(!this.props.collapse)
                    }
                  ></i>
                </Col>
              </Row>
              <Row style={{ background: "#fff" }}>
                <div className="color-outer">
                  <button
                    style={{
                      background: colorCat === "standard" ? "#fff" : "#e1e6eb",
                      border:
                        colorCat === "standard"
                          ? "1px solid #00b5e2"
                          : "#e1e6eb",
                    }}
                    onClick={() => this.colorCategory("standard")}
                  >
                    Standard
                  </button>
                  <button
                    style={{
                      background: colorCat === "recommend" ? "#fff" : "#e1e6eb",
                      border:
                        colorCat === "recommend"
                          ? "1px solid #00b5e2"
                          : "#e1e6eb",
                    }}
                    onClick={() => this.colorCategory("recommend")}
                  >
                    Recommended
                  </button>
                  <button
                    style={{
                      background: colorCat === "custom" ? "#fff" : "#e1e6eb",
                      border:
                        colorCat === "custom" ? "1px solid #00b5e2" : "#e1e6eb",
                    }}
                    onClick={() => this.colorCategory("custom")}
                  >
                    Custom
                  </button>
                </div>
              </Row>
              {colorCat === "standard" ? (
                <Row>
                  <Col>
                    <p></p>
                    <div className="solid-colors">
                      {INIT_SOLID_COLORS?.map((item) => (
                        <span
                          key={item}
                          className={
                            item === (canvas && canvas.backgroundColor)
                              ? "activeSolidcolor solidcolor"
                              : "solidcolor"
                          }
                          style={{
                            backgroundColor: item,
                          }}
                          onClick={() => this.setBGcolor(item)}
                        />
                      ))}

                      {this.state.bgcolArray?.map((colorval, index) => {
                        return colorval ? (
                          <span
                            key={index}
                            style={{
                              background: colorval,
                            }}
                            className="solidcolor"
                            onClick={() => this.setBGcolor(colorval)}
                          ></span>
                        ) : null;
                      })}
                    </div>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              {colorCat === "recommend" ? (
                <Row>
                  <Col>
                    <p></p>
                    <div className="gradients-colors">
                      <span
                        className={
                          canvas &&
                          canvas.backgroundColor.colorStops &&
                          canvas.backgroundColor.colorStops[0].color ===
                            "#62ff00"
                            ? "activeSolidcolor grdcol1 grdcolor"
                            : "grdcol1 grdcolor"
                        }
                        onClick={() =>
                          this.setGradientBGcolor(
                            "#62ff00",
                            "yellow",
                            "vertical"
                          )
                        }
                      />
                      <span
                        className={
                          canvas &&
                          canvas.backgroundColor.colorStops &&
                          canvas.backgroundColor.colorStops[0].color ===
                            "#FF0000"
                            ? "activeSolidcolor grdcol2 grdcolor"
                            : "grdcol2 grdcolor"
                        }
                        onClick={() =>
                          this.setGradientBGcolor(
                            "#FF0000",
                            "yellow",
                            "horizontal"
                          )
                        }
                      />
                      <span
                        className={
                          canvas &&
                          canvas.backgroundColor.colorStops &&
                          canvas.backgroundColor.colorStops[0].color ===
                            "#ff9900"
                            ? "activeSolidcolor grdcol3 grdcolor"
                            : "grdcol3 grdcolor"
                        }
                        onClick={() =>
                          this.setGradientBGcolor(
                            "#ff9900",
                            "#39d4cd",
                            "horizontal"
                          )
                        }
                      />
                      <span
                        className={
                          canvas &&
                          canvas.backgroundColor.colorStops &&
                          canvas.backgroundColor.colorStops[0].color ===
                            "#FF1919"
                            ? "activeSolidcolor grdcol4 grdcolor"
                            : "grdcol4 grdcolor"
                        }
                        onClick={() =>
                          this.setGradientBGcolor(
                            "#FF1919",
                            "#fff",
                            "horizontal"
                          )
                        }
                      />
                    </div>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              {colorCat === "custom" ? (
                <Row className="custom-color">
                  <Col>
                    <p></p>
                    <div className="solid-colors">
                      <div className="popover">
                        <div className="cover" onClick={this.bgcolorClose} />
                        <SketchPicker
                          color={
                            canvas.backgroundColor.colorStops
                              ? canvas.backgroundColor.colorStops[0].color
                              : canvas.backgroundColor
                          }
                          onChange={this.setCanvasFill}
                        />
                      </div>
                    </div>
                  </Col>
                </Row>
              ) : (
                ""
              )}
            </Container>
          </TabPanel>
          <TabPanel tabId="vertical-tab-four" className="shape-gallery">
            <Container className="text-editer">
              <Row className="img-title">
                <Col
                  sm="12"
                  className={`gallery-title d-flex justify-content-center`}
                  style={{
                    height: "50px",
                    paddingTop: "5%",
                    fontSize: "14px",
                    color: "#697582",
                    maxWidth: "max-content",
                  }}
                >
                  Shapes
                </Col>
                <Tooltip content="Instruction" direction="up">
                  <Col>
                    <BsFillInfoCircleFill
                      onClick={this.showInstruction}
                      style={{ cursor: "pointer" }}
                    />
                  </Col>
                </Tooltip>
                <Col
                  style={{
                    maxWidth: "max-content",
                    width: "auto",
                  }}
                >
                  <i
                    className="fa fa-times"
                    onClick={() =>
                      this.props.toggleSidebar(!this.props.collapse)
                    }
                  ></i>
                </Col>
              </Row>
              <TabContent activeTab={this.state.activeTab}>
                <TabPane tabId="1">
                  <Row>
                    <Col
                      sm="12"
                      style={{
                        backgroundColor: "white",
                      }}
                    >
                      <div className="patterns shapes">
                        <div className="patterns shapes-inner">
                          <i
                            className="shape fas fa-circle "
                            onClick={() =>
                              systemType !== "desktop" && this.fillImage(1)
                            }
                          ></i>
                          {systemType === "desktop" && (
                            <i
                              className="add-shape fa fa-plus"
                              onClick={() => this.fillImage(1)}
                            ></i>
                          )}
                        </div>
                        <div className="patterns shapes-inner">
                          <i
                            className="shape fas fa-square-full"
                            onClick={() =>
                              systemType !== "desktop" && this.fillImage(2)
                            }
                          ></i>
                          {systemType === "desktop" && (
                            <i
                              className="add-shape fa fa-plus"
                              onClick={() => this.fillImage(2)}
                            ></i>
                          )}
                        </div>
                        <div className="patterns shapes-inner">
                          <IoStarSharp
                            className="shape fas fa-star "
                            onClick={() =>
                              systemType !== "desktop" && this.fillImage(3)
                            }
                          />
                          {systemType === "desktop" && (
                            <i
                              className="add-shape fa fa-plus"
                              onClick={() => this.fillImage(3)}
                            ></i>
                          )}
                        </div>
                        <div className="patterns shapes-inner">
                          <i
                            className="shape bi bi-hexagon-fill"
                            onClick={() =>
                              systemType !== "desktop" && this.fillImage(4)
                            }
                          ></i>
                          {systemType === "desktop" && (
                            <i
                              className="add-shape fa fa-plus"
                              onClick={() => this.fillImage(4)}
                            ></i>
                          )}
                        </div>
                        <div className="patterns shapes-inner">
                          <IoTriangleSharp
                            className="shape"
                            onClick={() =>
                              systemType !== "desktop" && this.fillImage(5)
                            }
                          />
                          {systemType === "desktop" && (
                            <i
                              className="add-shape fa fa-plus"
                              onClick={() => this.fillImage(5)}
                            ></i>
                          )}
                        </div>
                        <div className="patterns shapes-inner">
                          <i
                            className="shape bi bi-pentagon-fill"
                            onClick={() =>
                              systemType !== "desktop" && this.fillImage(6)
                            }
                          ></i>
                          {systemType === "desktop" && (
                            <i
                              className="add-shape fa fa-plus"
                              onClick={() => this.fillImage(6)}
                            ></i>
                          )}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Container>
          </TabPanel>
          <TabPanel tabId="vertical-tab-two">
            <Container className="text-editer layers-gallery">
              <Row className="layer-title">
                <Col
                  sm="12"
                  className={`gallery-title d-flex justify-content-center`}
                  style={{
                    height: "50px",
                    paddingTop: "5%",
                    fontSize: "14px",
                    color: "#697582",
                    maxWidth: "max-content",
                  }}
                >
                  Layers
                </Col>
                <Tooltip content="Instruction" direction="up">
                  <Col>
                    <BsFillInfoCircleFill
                      onClick={this.showInstruction}
                      style={{ cursor: "pointer" }}
                    />
                  </Col>
                </Tooltip>
                <Col
                  style={{
                    maxWidth: "max-content",
                    width: "auto",
                  }}
                >
                  <i
                    className="fa fa-times"
                    onClick={() =>
                      this.props.toggleSidebar(!this.props.collapse)
                    }
                  ></i>
                </Col>
              </Row>
              <Row>
                <Col sm="12" style={{ backgroundColor: "#fff" }}>
                  {sortedArray.length > 0 ? (
                    <DragDropContext onDragEnd={this.handleDragEnd}>
                      <Droppable droppableId="layer-cards">
                        {(provided) => (
                          <div
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                          >
                            {sortedArray.map(
                              (item, i) =>
                                item.name !== "selectionRect" && (
                                  <Draggable
                                    key={i}
                                    draggableId={`item-${i}`}
                                    index={i}
                                    isDragDisabled={item.isLocked}
                                  >
                                    {(provided) => (
                                      <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        className={`layer-card ${
                                          item.isLocked ? "locked" : ""
                                        }`}
                                        onClick={(e) => {
                                          if (
                                            !e.target.classList.contains(
                                              "fa-trash-alt"
                                            )
                                          ) {
                                            this.layerActive(item);
                                          }
                                        }}
                                      >
                                        {item.type === "textbox" && (
                                          <div
                                            className="layer-shapes"
                                            style={{
                                              color: item.fill,
                                              fontFamily: item.fontFamily,
                                            }}
                                          >
                                            {item.text}
                                            <i
                                              className="far fa-trash-alt text arrow"
                                              style={{
                                                cursor:
                                                  item.isLocked && "no-drop",
                                              }}
                                              onClick={(e) => {
                                                if (!item.isLocked) {
                                                  e.stopPropagation();
                                                  this.props.deleteLayer(item);
                                                }
                                              }}
                                            ></i>
                                          </div>
                                        )}
                                        {item.type === "circle" && (
                                          <div className="layer-shapes">
                                            <i
                                              style={{
                                                fontSize: "1.5rem",
                                                color: item.fill,
                                                cursor:
                                                  item.isLocked && "no-drop",
                                              }}
                                              className="shape fas fa-circle "
                                            ></i>
                                            <p>Circle</p>
                                            <i
                                              className="far fa-trash-alt text arrow"
                                              style={{
                                                cursor:
                                                  item.isLocked && "no-drop",
                                              }}
                                              onClick={() => {
                                                if (!item.isLocked) {
                                                  this.props.deleteLayer(item);
                                                }
                                              }}
                                            ></i>
                                          </div>
                                        )}
                                        {item.type === "image" && (
                                          <div className="layer-shapes">
                                            <img src={item.src} alt="layers" />
                                            <p
                                              style={{ wordBreak: "break-all" }}
                                            >
                                              {item.imgName
                                                ? item.imgName
                                                : "Default Image"}
                                            </p>
                                            <i
                                              className="far fa-trash-alt text arrow"
                                              style={{
                                                cursor:
                                                  item.isLocked && "no-drop",
                                              }}
                                              onClick={() => {
                                                if (!item.isLocked) {
                                                  this.props.deleteLayer(item);
                                                }
                                              }}
                                            ></i>
                                          </div>
                                        )}
                                        {item.type === "rect" && (
                                          <div className="layer-shapes">
                                            <i
                                              style={{
                                                fontSize: "1.5rem",
                                                color: item.fill,
                                                cursor:
                                                  item.isLocked && "no-drop",
                                              }}
                                              className="shape fas fa-square "
                                            ></i>
                                            <p>Square</p>
                                            <i
                                              className="far fa-trash-alt text arrow"
                                              style={{
                                                cursor:
                                                  item.isLocked && "no-drop",
                                              }}
                                              onClick={() => {
                                                if (!item.isLocked) {
                                                  this.props.deleteLayer(item);
                                                }
                                              }}
                                            ></i>
                                          </div>
                                        )}
                                        {(item.layerType === "star" ||
                                          item?.points?.length === 10) && (
                                          <div className="layer-shapes">
                                            <i
                                              style={{
                                                fontSize: "1.5rem",
                                                color: item.fill,
                                                cursor:
                                                  item.isLocked && "no-drop",
                                              }}
                                              className="shape fas fa-star "
                                            ></i>
                                            <p>Star</p>
                                            <i
                                              className="far fa-trash-alt text arrow"
                                              style={{
                                                cursor:
                                                  item.isLocked && "no-drop",
                                              }}
                                              onClick={() => {
                                                if (!item.isLocked) {
                                                  this.props.deleteLayer(item);
                                                }
                                              }}
                                            ></i>
                                          </div>
                                        )}
                                        {(item.layerType === "hexagon" ||
                                          item?.points?.length === 6) && (
                                          <div className="layer-shapes">
                                            <i
                                              style={{
                                                fontSize: "1.5rem",
                                                color: item.fill,
                                                cursor:
                                                  item.isLocked && "no-drop",
                                              }}
                                              className="shape bi bi-hexagon-fill"
                                            ></i>
                                            <p>Hexagon</p>
                                            <i
                                              className="far fa-trash-alt text arrow"
                                              style={{
                                                cursor:
                                                  item.isLocked && "no-drop",
                                              }}
                                              onClick={() => {
                                                if (!item.isLocked) {
                                                  this.props.deleteLayer(item);
                                                }
                                              }}
                                            ></i>
                                          </div>
                                        )}
                                        {(item.layerType === "triangle" ||
                                          item?.points?.length === 3) && (
                                          <div className="layer-shapes">
                                            <i
                                              style={{
                                                fontSize: "1.5rem",
                                                color: item.fill,
                                                cursor:
                                                  item.isLocked && "no-drop",
                                              }}
                                              className="shape bi bi-triangle-fill"
                                            ></i>
                                            <p>Triangle</p>
                                            <i
                                              className="far fa-trash-alt text arrow"
                                              style={{
                                                cursor:
                                                  item.isLocked && "no-drop",
                                              }}
                                              onClick={() => {
                                                if (!item.isLocked) {
                                                  this.props.deleteLayer(item);
                                                }
                                              }}
                                            ></i>
                                          </div>
                                        )}
                                        {(item.layerType === "pentagon" ||
                                          item?.points?.length === 5) && (
                                          <div className="layer-shapes">
                                            <i
                                              style={{
                                                fontSize: "1.5rem",
                                                color: item.fill,
                                                cursor:
                                                  item.isLocked && "no-drop",
                                              }}
                                              className="shape bi bi-pentagon-fill"
                                            ></i>
                                            <p>Pentagon</p>
                                            <i
                                              className="far fa-trash-alt text arrow"
                                              style={{
                                                cursor:
                                                  item.isLocked && "no-drop",
                                              }}
                                              onClick={() => {
                                                if (!item.isLocked) {
                                                  this.props.deleteLayer(item);
                                                }
                                              }}
                                            ></i>
                                          </div>
                                        )}
                                        {/* Your card content here */}
                                      </div>
                                    )}
                                  </Draggable>
                                )
                            )}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  ) : (
                    <div className="layer-no-content">No Layers Found</div>
                  )}
                </Col>
              </Row>
            </Container>
          </TabPanel>
        </div>
        <div className="mobilenav" style={{ width: "100%" }}>
          <TabContent
            activeTab={activeTab}
            style={{
              backgroundColor: "#F1F1F1",
              border: "0.5px solid grey",
            }}
          >
            <TabPane tabId="1">
              <Container className="img-editor">
                <Row className="img-title">
                  <Col
                    sm="12"
                    className={`d-flex justify-content-center`}
                    style={{
                      height: "50px",
                      padding: "15px",
                      fontSize: "14px",
                      color: "#697582",
                      maxWidth: "max-content",
                    }}
                  >
                    Image Gallery
                  </Col>
                  <Col
                    style={{
                      maxWidth: "max-content",
                      width: "auto",
                    }}
                  >
                    <i
                      className="fa fa-times"
                      onClick={() => this.props.mobiletoggleSidebar()}
                    ></i>
                  </Col>
                </Row>
                <TabContent activeTab={this.state.imgactiveTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12" style={{ backgroundColor: "#fff" }}>
                        <div
                          className="dcl-upload-button"
                          onDrop={this.handleDrop}
                          onDragOver={this.handleDragOver}
                        >
                          {" "}
                          <label
                            htmlFor="gallery-upload"
                            className=" dcl-button dcl-button--upload dcl-button--full-width"
                          >
                            Upload Image
                          </label>
                          <form>
                            <input
                              id="gallery-upload"
                              type="file"
                              onChange={this.shapeUpload}
                              accept=".jpg, .jpeg, .png, .gif"
                            />
                          </form>
                        </div>
                      </Col>
                    </Row>
                    {this.props.selectedImages &&
                    this.props.selectedImages.length > 0 ? (
                      <Row className="image-preview">
                        <div className="uploads-title">
                          Uploads ({this.props.selectedImages.length})
                        </div>
                        {this.props.selectedImages.map((image, index) => {
                          const imageObject = this.props.canvas._objects.find(
                            (obj) => obj.id === image.id
                          );
                          const isUsed = imageObject !== undefined;
                          return (
                            <div className="image-container" key={index}>
                              <div className="image-container-inner">
                                <div
                                  className={`used-image-outer ${
                                    isUsed ? "used" : ""
                                  }`}
                                >
                                  {isUsed && (
                                    <span className="used-image">Used</span>
                                  )}
                                </div>
                                <div>
                                  <img
                                    className={
                                      this.props.selectedImages.length > 4
                                        ? "image-upload-gallery-fox"
                                        : "image-upload-gallery"
                                    }
                                    key={index}
                                    src={image.image_url}
                                    alt={`Selected Preview ${index + 1}`}
                                  />
                                </div>
                                <div className="icon-outer">
                                  <i
                                    onClick={() => this.deleteImage(image.id)}
                                    className="fa fa-trash"
                                    aria-hidden="true"
                                  ></i>
                                  <i
                                    className="fa fa-plus"
                                    onClick={() => {
                                      this.addImageOnCanvas(
                                        image.image_url,
                                        image.id,
                                        image.fileName
                                      );
                                      this.props.mobiletoggleSidebar();
                                    }}
                                  ></i>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </Row>
                    ) : (
                      <Row>
                        {this.props?.previewLoader.length === 0 && (
                          <>
                            <p className="img-text">
                              Click and drag an image from your gallery to the
                              design area. Supported file type (.jpg, .jpeg,
                              .png, .gif)
                            </p>
                            <div className="img-upload">
                              <i className="fas fa-image fa-2x text-muted "></i>
                            </div>
                          </>
                        )}
                      </Row>
                    )}
                    <Row
                      className={
                        loaderActive
                          ? "loader-image-preview loader-active"
                          : "loader-image-preview"
                      }
                    >
                      {this.props?.previewLoader.map((image, index) => {
                        return (
                          <div className="loader-image-container" key={index}>
                            {image.isLoading && (
                              <div className="spinner-border-outer">
                                <div className="spinner-border" role="status">
                                  <span className="sr-only">Loading...</span>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </Row>
                  </TabPane>
                </TabContent>
              </Container>
            </TabPane>
            <TabPane tabId="2">
              <Container className="text-editor text-gallery">
                <Row className="img-title">
                  <Col
                    sm="12"
                    className={`d-flex justify-content-center`}
                    style={{
                      height: "50px",
                      paddingTop: "15px",
                      fontSize: "14px",
                      color: "#697582",
                      maxWidth: "max-content",
                    }}
                  >
                    Text
                  </Col>
                  <Col
                    style={{
                      maxWidth: "max-content",
                      width: "auto",
                    }}
                  >
                    <i
                      className="fa fa-times"
                      onClick={() => this.props.mobiletoggleSidebar()}
                    ></i>
                  </Col>
                </Row>
                <Row>
                  <Col
                    sm="12"
                    style={{
                      backgroundColor: "#fff",
                      height: "378px",
                      overflow: "auto",
                    }}
                  >
                    <div
                      className="dcl-upload-button"
                      onClick={() => {
                        this.addText("title", "Type text here", false);
                        this.props.mobiletoggleSidebar();
                      }}
                    >
                      {" "}
                      <label className=" dcl-button dcl-button--upload dcl-button--full-width">
                        Add Text
                      </label>
                    </div>
                    <div className="sample1-text">
                      <div
                        onClick={() => {
                          this.addSampleText1("title", "", false);
                          this.props.mobiletoggleSidebar();
                        }}
                      >
                        <p className="sample-text-p1">Life is an</p>
                        <p className="sample-text-p2">Adventure</p>
                      </div>
                      <div
                        style={{ marginTop: "-8px" }}
                        onClick={() => {
                          this.addSampleText2("title", "", false);
                          this.props.mobiletoggleSidebar();
                        }}
                      >
                        <p className="sample1-text-p1">EAT</p>
                        <p className="sample1-text-p2">SLEEP</p>
                        <p className="sample1-text-p3">REPEAT</p>
                      </div>
                    </div>
                    <div className="sample2-text">
                      <div
                        onClick={() => {
                          this.addSampleText3("title", "", false);
                          this.props.mobiletoggleSidebar();
                        }}
                      >
                        <p className="sample2-text-p1">CHEERS</p>
                        <p className="sample2-text-p2">TO THE HAPPY COUPLE</p>
                      </div>
                      <div
                        onClick={() => {
                          this.addSampleText4("title", "", false);
                          this.props.mobiletoggleSidebar();
                        }}
                      >
                        <p className="sample3-text-p1">Keep Being</p>
                        <p className="sample3-text-p2">AMAZING</p>
                      </div>
                    </div>
                    <div className="sample3-text">
                      <div
                        onClick={() => {
                          this.addSampleText5("title", "", false);
                          this.props.mobiletoggleSidebar();
                        }}
                      >
                        <p className="sample4-text-p1">Life Is An</p>
                        <p className="sample4-text-p2">Adventure</p>
                      </div>
                      <div
                        onClick={() => {
                          this.addSampleText6("title", "", false);
                          this.props.mobiletoggleSidebar();
                        }}
                      >
                        <p className="sample5-text-p1">Save</p>
                        <p className="sample5-text-p2">the</p>
                        <p className="sample5-text-p3">Date</p>
                      </div>
                      <div
                        onClick={() => {
                          this.addSampleText7("title", "", false);
                          this.props.mobiletoggleSidebar();
                        }}
                      >
                        <p className="sample6-text-p1">MY FIRST</p>
                        <p className="sample6-text-p2">YEARS</p>
                      </div>
                      <div
                        onClick={() => {
                          this.addSampleText8("title", "", false);
                          this.props.mobiletoggleSidebar();
                        }}
                      >
                        <p className="sample7-text-p1">PEACE</p>
                        <p className="sample7-text-p2">LOVE</p>
                        <p className="sample7-text-p3">PETS</p>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </TabPane>
            <TabPane tabId="3">
              <Container className="color-gallery">
                <Row className="color-title">
                  <Col
                    sm="12"
                    className={`d-flex justify-content-center`}
                    style={{
                      height: "50px",
                      paddingTop: "15px",
                      fontSize: "14px",
                      color: "#697582",
                      maxWidth: "max-content",
                    }}
                  >
                    Add Background Color
                  </Col>
                  <Col
                    style={{
                      maxWidth: "max-content",
                      width: "auto",
                    }}
                  >
                    <i
                      className="fa fa-times"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.props.mobiletoggleSidebar()}
                    ></i>
                  </Col>
                </Row>
                <Row style={{ background: "#fff" }}>
                  <div className="color-outer">
                    <button
                      style={{
                        background:
                          colorCat === "standard" ? "#fff" : "#e1e6eb",
                        border:
                          colorCat === "standard"
                            ? "1px solid #00b5e2"
                            : "#e1e6eb",
                      }}
                      onClick={() => this.colorCategory("standard")}
                    >
                      Standard
                    </button>
                    <button
                      style={{
                        background:
                          colorCat === "recommend" ? "#fff" : "#e1e6eb",
                        border:
                          colorCat === "recommend"
                            ? "1px solid #00b5e2"
                            : "#e1e6eb",
                      }}
                      onClick={() => this.colorCategory("recommend")}
                    >
                      Recommended
                    </button>
                    <button
                      style={{
                        background: colorCat === "custom" ? "#fff" : "#e1e6eb",
                        border:
                          colorCat === "custom"
                            ? "1px solid #00b5e2"
                            : "#e1e6eb",
                      }}
                      onClick={() => this.colorCategory("custom")}
                    >
                      Custom
                    </button>
                  </div>
                </Row>
                {colorCat === "standard" ? (
                  <Row>
                    <Col>
                      <p></p>
                      <div className="solid-colors">
                        {INIT_SOLID_COLORS?.map((item) => (
                          <span
                            key={item}
                            className={
                              item === (canvas && canvas.backgroundColor)
                                ? "activeSolidcolor solidcolor"
                                : "solidcolor"
                            }
                            style={{
                              backgroundColor: item,
                            }}
                            onClick={() => {
                              this.setBGcolor(item);
                              this.props.mobiletoggleSidebar();
                            }}
                          />
                        ))}

                        {this.state.bgcolArray?.map((colorval, index) => {
                          return colorval ? (
                            <span
                              key={index}
                              style={{
                                background: colorval,
                              }}
                              className="solidcolor"
                              onClick={() => {
                                this.setBGcolor(colorval);
                                this.props.mobiletoggleSidebar();
                              }}
                            ></span>
                          ) : null;
                        })}
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                {colorCat === "recommend" ? (
                  <Row>
                    <Col>
                      <p></p>
                      <div className="gradients-colors">
                        <span
                          className={
                            canvas &&
                            canvas.backgroundColor.colorStops &&
                            canvas.backgroundColor.colorStops[0].color ===
                              "#62ff00"
                              ? "activeSolidcolor grdcol1 grdcolor"
                              : "grdcol1 grdcolor"
                          }
                          onClick={() => {
                            this.setGradientBGcolor(
                              "#62ff00",
                              "yellow",
                              "vertical"
                            );
                            this.props.mobiletoggleSidebar();
                          }}
                        />
                        <span
                          className={
                            canvas &&
                            canvas.backgroundColor.colorStops &&
                            canvas.backgroundColor.colorStops[0].color ===
                              "#FF0000"
                              ? "activeSolidcolor grdcol2 grdcolor"
                              : "grdcol2 grdcolor"
                          }
                          onClick={() => {
                            this.setGradientBGcolor(
                              "#FF0000",
                              "yellow",
                              "horizontal"
                            );
                            this.props.mobiletoggleSidebar();
                          }}
                        />
                        <span
                          className={
                            canvas &&
                            canvas.backgroundColor.colorStops &&
                            canvas.backgroundColor.colorStops[0].color ===
                              "#ff9900"
                              ? "activeSolidcolor grdcol3 grdcolor"
                              : "grdcol3 grdcolor"
                          }
                          onClick={() => {
                            this.setGradientBGcolor(
                              "#ff9900",
                              "#39d4cd",
                              "horizontal"
                            );
                            this.props.mobiletoggleSidebar();
                          }}
                        />
                        <span
                          className={
                            canvas &&
                            canvas.backgroundColor.colorStops &&
                            canvas.backgroundColor.colorStops[0].color ===
                              "#FF1919"
                              ? "activeSolidcolor grdcol4 grdcolor"
                              : "grdcol4 grdcolor"
                          }
                          onClick={() => {
                            this.setGradientBGcolor(
                              "#FF1919",
                              "#fff",
                              "horizontal"
                            );
                            this.props.mobiletoggleSidebar();
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
                {colorCat === "custom" ? (
                  <Row className="custom-color">
                    <Col>
                      <p></p>
                      <div className="solid-colors">
                        <div className="popover">
                          <div className="cover" onClick={this.bgcolorClose} />
                          <SketchPicker
                            color={
                              canvas.backgroundColor.colorStops
                                ? canvas.backgroundColor.colorStops[0].color
                                : canvas.backgroundColor
                            }
                            onChange={this.setCanvasFill}
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  ""
                )}
              </Container>
            </TabPane>
            <TabPane tabId="4">
              <Container
                className="text-editer clip-gallery"
                style={{ height: "30%" }}
              >
                <Row className="img-title">
                  <Col
                    sm="12"
                    className={`d-flex justify-content-center`}
                    style={{
                      height: "50px",
                      paddingTop: "15px",
                      fontSize: "14px",
                      color: "#697582",
                      maxWidth: "max-content",
                    }}
                  >
                    Clip&nbsp;Art
                  </Col>
                  <Col
                    style={{
                      maxWidth: "max-content",
                      width: "auto",
                    }}
                  >
                    <i
                      className="fa fa-times"
                      onClick={() => this.props.mobiletoggleSidebar()}
                    ></i>
                  </Col>
                </Row>
                <Row
                  style={{
                    backgroundColor: "white",
                  }}
                >
                  <Col sm="12">
                    {selectedCategoryObj ? (
                      <>
                        <div className="dcl-upload-button">
                          <input
                            type="text"
                            placeholder="Search"
                            value={this.props.searchQuery}
                            onChange={this.props.handleSearchQueryChange}
                          />
                          <i className="fa fa-search" aria-hidden="true"></i>
                        </div>
                        <div className="dcl-browse-button">
                          <label>Browse by category</label>
                          <Select
                            className="clipart-dropdown"
                            value={selectedCategoryObj}
                            onChange={this.handleCategorySelect}
                            options={clipartCategories}
                            styles={{
                              option: optionStyles, // Apply the custom styles to the options
                            }}
                          />
                        </div>
                        <div
                          className="clipimage-container-outer"
                          style={{
                            overflow:
                              selectedCategoryObj.images.filter((image) =>
                                image.name
                                  .toLowerCase()
                                  .includes(
                                    this.props.searchQuery.toLowerCase()
                                  )
                              ).length < 4 &&
                              this.props.searchQuery !== "" &&
                              "hidden",
                          }}
                        >
                          {selectedCategoryObj.images
                            .filter((image) =>
                              image.name
                                .toLowerCase()
                                .includes(this.props.searchQuery.toLowerCase())
                            )
                            .map((image, i) => (
                              <div className="clipimage-container" key={i}>
                                <div className="clipimage-container-inner">
                                  <img
                                    key={image.name}
                                    src={image.url}
                                    alt={image.name}
                                    className="clipart-img"
                                    onClick={() => {
                                      systemType !== "desktop" &&
                                        this.addImageOnCanvas(
                                          image.url,
                                          "",
                                          image.name
                                        );
                                      this.props.mobiletoggleSidebar();
                                    }}
                                  />
                                  {systemType === "desktop" && (
                                    <i
                                      className="fa fa-plus add-clipart"
                                      onClick={() => {
                                        this.addImageOnCanvas(
                                          image.url,
                                          "",
                                          image.name
                                        );
                                        this.props.mobiletoggleSidebar();
                                      }}
                                    ></i>
                                  )}
                                </div>
                              </div>
                            ))}
                        </div>
                        {selectedCategoryObj.images.filter((image) =>
                          image.name
                            .toLowerCase()
                            .includes(this.props.searchQuery.toLowerCase())
                        ).length === 0 &&
                          this.props.searchQuery !== "" && (
                            <div className="clipart-empty">
                              No Clipart Images found
                            </div>
                          )}
                      </>
                    ) : (
                      <>
                        <div className="clip-gallery-no-image-icon">
                          <LuImageOff />
                        </div>
                        <p className="clip-gallery-no-image-text">
                          No Clipart Images found
                        </p>
                      </>
                    )}
                  </Col>
                </Row>
              </Container>
            </TabPane>
            <TabPane tabId="5">
              <Container
                className="text-editer shape-gallery"
                style={{ height: "30%" }}
              >
                <Row className="img-title">
                  <Col
                    sm="12"
                    className={`d-flex justify-content-center`}
                    style={{
                      height: "50px",
                      paddingTop: "15px",
                      fontSize: "14px",
                      color: "#697582",
                      maxWidth: "max-content",
                    }}
                  >
                    Shapes
                  </Col>
                  <Col
                    style={{
                      maxWidth: "max-content",
                      width: "auto",
                    }}
                  >
                    <i
                      className="fa fa-times"
                      onClick={() => this.props.mobiletoggleSidebar()}
                    ></i>
                  </Col>
                </Row>
                <Row>
                  <Col
                    sm="12"
                    style={{
                      backgroundColor: "white",
                    }}
                  >
                    <div className="patterns shapes">
                      <div className="patterns shapes-inner">
                        <i
                          className="shape fas fa-circle "
                          onClick={() => {
                            systemType !== "desktop" && this.fillImage(1);
                            this.props.mobiletoggleSidebar();
                          }}
                        ></i>
                        {systemType === "desktop" && (
                          <i
                            className="add-shape fa fa-plus"
                            onClick={() => {
                              this.fillImage(1);
                              this.props.mobiletoggleSidebar();
                            }}
                          ></i>
                        )}
                      </div>
                      <div className="patterns shapes-inner">
                        <i
                          className="shape fas fa-square-full"
                          onClick={() => {
                            systemType !== "desktop" && this.fillImage(2);
                            this.props.mobiletoggleSidebar();
                          }}
                        ></i>
                        {systemType === "desktop" && (
                          <i
                            className="add-shape fa fa-plus"
                            onClick={() => {
                              this.fillImage(2);
                              this.props.mobiletoggleSidebar();
                            }}
                          ></i>
                        )}
                      </div>
                      <div className="patterns shapes-inner">
                        <IoStarSharp
                          className="shape fas fa-star "
                          onClick={() => {
                            systemType !== "desktop" && this.fillImage(3);
                            this.props.mobiletoggleSidebar();
                          }}
                        />
                        {systemType === "desktop" && (
                          <i
                            className="add-shape fa fa-plus"
                            onClick={() => {
                              this.fillImage(3);
                              this.props.mobiletoggleSidebar();
                            }}
                          ></i>
                        )}
                      </div>
                      <div className="patterns shapes-inner">
                        <i
                          className="shape bi bi-hexagon-fill"
                          onClick={() => {
                            systemType !== "desktop" && this.fillImage(4);
                            this.props.mobiletoggleSidebar();
                          }}
                        ></i>
                        {systemType === "desktop" && (
                          <i
                            className="add-shape fa fa-plus"
                            onClick={() => {
                              this.fillImage(4);
                              this.props.mobiletoggleSidebar();
                            }}
                          ></i>
                        )}
                      </div>
                      <div className="patterns shapes-inner">
                        <IoTriangleSharp
                          className="shape bi bi-triangle-fill"
                          onClick={() => {
                            systemType !== "desktop" && this.fillImage(5);
                            this.props.mobiletoggleSidebar();
                          }}
                        />
                        {systemType === "desktop" && (
                          <i
                            className="add-shape fa fa-plus"
                            onClick={() => {
                              this.fillImage(5);
                              this.props.mobiletoggleSidebar();
                            }}
                          ></i>
                        )}
                      </div>
                      <div className="patterns shapes-inner">
                        <i
                          className="shape bi bi-pentagon-fill"
                          onClick={() => {
                            systemType !== "desktop" && this.fillImage(6);
                            this.props.mobiletoggleSidebar();
                          }}
                        ></i>
                        {systemType === "desktop" && (
                          <i
                            className="add-shape fa fa-plus"
                            onClick={() => {
                              this.fillImage(6);
                              this.props.mobiletoggleSidebar();
                            }}
                          ></i>
                        )}
                      </div>
                    </div>
                  </Col>
                </Row>
              </Container>
            </TabPane>
            <TabPane tabId="6">
              <Container
                className="text-editer layers-gallery"
                style={{ height: "30%" }}
              >
                <Row className="img-title">
                  <Col
                    sm="12"
                    className={`d-flex justify-content-center`}
                    style={{
                      height: "50px",
                      paddingTop: "15px",
                      fontSize: "14px",
                      color: "#697582",
                      maxWidth: "max-content",
                    }}
                  >
                    Layers
                  </Col>
                  <Col
                    style={{
                      maxWidth: "max-content",
                      width: "auto",
                    }}
                  >
                    <i
                      className="fa fa-times"
                      onClick={() => this.props.mobiletoggleSidebar()}
                    ></i>
                  </Col>
                </Row>
                <Row className="shape-row">
                  <Col sm="12" style={{ backgroundColor: "#fff" }}>
                    {sortedArray.length > 0 ? (
                      <DragDropContext onDragEnd={this.handleDragEnd}>
                        <Droppable droppableId="layer-cards">
                          {(provided) => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                            >
                              {sortedArray.map(
                                (item, i) =>
                                  item.name !== "selectionRect" && (
                                    <Draggable
                                      key={i}
                                      draggableId={`item-${i}`}
                                      index={i}
                                      isDragDisabled={item.isLocked}
                                    >
                                      {(provided) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          className={`layer-card ${
                                            item.isLocked ? "locked" : ""
                                          }`}
                                          onClick={(e) => {
                                            if (
                                              !e.target.classList.contains(
                                                "fa-trash-alt"
                                              )
                                            ) {
                                              this.layerActive(item);
                                            }
                                          }}
                                        >
                                          {item.type === "textbox" && (
                                            <div
                                              className="layer-shapes"
                                              style={{
                                                color: item.fill,
                                                fontFamily: item.fontFamily,
                                              }}
                                            >
                                              {item.text}
                                              <i
                                                className="far fa-trash-alt text arrow"
                                                style={{
                                                  cursor:
                                                    item.isLocked && "no-drop",
                                                }}
                                                onClick={(e) => {
                                                  if (!item.isLocked) {
                                                    e.stopPropagation();
                                                    this.props.deleteLayer(
                                                      item
                                                    );
                                                  }
                                                }}
                                              ></i>
                                            </div>
                                          )}
                                          {item.type === "circle" && (
                                            <div className="layer-shapes">
                                              <i
                                                style={{
                                                  fontSize: "1.5rem",
                                                  color: item.fill,
                                                  cursor:
                                                    item.isLocked && "no-drop",
                                                }}
                                                className="shape fas fa-circle "
                                              ></i>
                                              <p>Circle</p>
                                              <i
                                                className="far fa-trash-alt text arrow"
                                                style={{
                                                  cursor:
                                                    item.isLocked && "no-drop",
                                                }}
                                                onClick={() => {
                                                  if (!item.isLocked) {
                                                    this.props.deleteLayer(
                                                      item
                                                    );
                                                  }
                                                }}
                                              ></i>
                                            </div>
                                          )}
                                          {item.type === "image" && (
                                            <div className="layer-shapes">
                                              <img
                                                src={item.src}
                                                alt="layers"
                                              />
                                              <p
                                                style={{
                                                  wordBreak: "break-all",
                                                }}
                                              >
                                                {item.imgName
                                                  ? item.imgName
                                                  : "Default Image"}
                                              </p>
                                              <i
                                                className="far fa-trash-alt text arrow"
                                                style={{
                                                  cursor:
                                                    item.isLocked && "no-drop",
                                                }}
                                                onClick={() => {
                                                  if (!item.isLocked) {
                                                    this.props.deleteLayer(
                                                      item
                                                    );
                                                  }
                                                }}
                                              ></i>
                                            </div>
                                          )}
                                          {item.type === "rect" && (
                                            <div className="layer-shapes">
                                              <i
                                                style={{
                                                  fontSize: "1.5rem",
                                                  color: item.fill,
                                                  cursor:
                                                    item.isLocked && "no-drop",
                                                }}
                                                className="shape fas fa-square "
                                              ></i>
                                              <p>Square</p>
                                              <i
                                                className="far fa-trash-alt text arrow"
                                                style={{
                                                  cursor:
                                                    item.isLocked && "no-drop",
                                                }}
                                                onClick={() => {
                                                  if (!item.isLocked) {
                                                    this.props.deleteLayer(
                                                      item
                                                    );
                                                  }
                                                }}
                                              ></i>
                                            </div>
                                          )}
                                          {(item.layerType === "star" ||
                                            item?.points?.length === 10) && (
                                            <div className="layer-shapes">
                                              <i
                                                style={{
                                                  fontSize: "1.5rem",
                                                  color: item.fill,
                                                  cursor:
                                                    item.isLocked && "no-drop",
                                                }}
                                                className="shape fas fa-star "
                                              ></i>
                                              <p>Star</p>
                                              <i
                                                className="far fa-trash-alt text arrow"
                                                style={{
                                                  cursor:
                                                    item.isLocked && "no-drop",
                                                }}
                                                onClick={() => {
                                                  if (!item.isLocked) {
                                                    this.props.deleteLayer(
                                                      item
                                                    );
                                                  }
                                                }}
                                              ></i>
                                            </div>
                                          )}
                                          {(item.layerType === "hexagon" ||
                                            item?.points?.length === 6) && (
                                            <div className="layer-shapes">
                                              <i
                                                style={{
                                                  fontSize: "1.5rem",
                                                  color: item.fill,
                                                  cursor:
                                                    item.isLocked && "no-drop",
                                                }}
                                                className="shape bi bi-hexagon-fill"
                                              ></i>
                                              <p>Hexagon</p>
                                              <i
                                                className="far fa-trash-alt text arrow"
                                                style={{
                                                  cursor:
                                                    item.isLocked && "no-drop",
                                                }}
                                                onClick={() => {
                                                  if (!item.isLocked) {
                                                    this.props.deleteLayer(
                                                      item
                                                    );
                                                  }
                                                }}
                                              ></i>
                                            </div>
                                          )}
                                          {(item.layerType === "triangle" ||
                                            item?.points?.length === 3) && (
                                            <div className="layer-shapes">
                                              <i
                                                style={{
                                                  fontSize: "1.5rem",
                                                  color: item.fill,
                                                  cursor:
                                                    item.isLocked && "no-drop",
                                                }}
                                                className="shape bi bi-triangle-fill"
                                              ></i>
                                              <p>Triangle</p>
                                              <i
                                                className="far fa-trash-alt text arrow"
                                                style={{
                                                  cursor:
                                                    item.isLocked && "no-drop",
                                                }}
                                                onClick={() => {
                                                  if (!item.isLocked) {
                                                    this.props.deleteLayer(
                                                      item
                                                    );
                                                  }
                                                }}
                                              ></i>
                                            </div>
                                          )}
                                          {(item.layerType === "pentagon" ||
                                            item?.points?.length === 5) && (
                                            <div className="layer-shapes">
                                              <i
                                                style={{
                                                  fontSize: "1.5rem",
                                                  color: item.fill,
                                                  cursor:
                                                    item.isLocked && "no-drop",
                                                }}
                                                className="shape bi bi-pentagon-fill"
                                              ></i>
                                              <p>Pentagon</p>
                                              <i
                                                className="far fa-trash-alt text arrow"
                                                style={{
                                                  cursor:
                                                    item.isLocked && "no-drop",
                                                }}
                                                onClick={() => {
                                                  if (!item.isLocked) {
                                                    this.props.deleteLayer(
                                                      item
                                                    );
                                                  }
                                                }}
                                              ></i>
                                            </div>
                                          )}
                                          {/* Your card content here */}
                                        </div>
                                      )}
                                    </Draggable>
                                  )
                              )}
                              {provided.placeholder}
                            </div>
                          )}
                        </Droppable>
                      </DragDropContext>
                    ) : (
                      <div className="layer-no-content">No Layers Found</div>
                    )}
                  </Col>
                </Row>
              </Container>
            </TabPane>
          </TabContent>
        </div>
        <Modal
          show={this.state.showIns}
          onHide={this.closeIns}
          size="default"
          id="ClearWarningModal"
          backdrop="static"
          keyboard={false}
        >
          <Modal.Header>
            <Modal.Title>
              <span style={{ fontSize: "24px" }}>Instruction</span>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p dangerouslySetInnerHTML={{ __html: this.props.instruction }}></p>
          </Modal.Body>
          <Modal.Footer style={{ justifyContent: "center" }}>
            <button className="btn btn-edit" onClick={this.closeIns}>
              Close
            </button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default LeftPanel;
