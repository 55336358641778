//export const HOST_SITE = "https://cap-front.cns72.com/";
// export const HOST_SITE = "https://cap.cnsfly.com/";
//export const LOGO_URL = "https://cnsdrive.com/cap/";
// export const LOGO_URL = "https://cnsd26.sg-host.com/";
export const WP_LOGIN =
  "https://cnsd26.sg-host.com/my-account/?p=login&redirect_to=";
//export const WP_LOGIN = 'https://2024.customizeaproduct.com/my-account/?p=login&redirect_to='
export const CartLogin = "https://cnsd26.sg-host.com/cart/";
//export const CartLogin = 'https://2024.customizeaproduct.com/cart/'
export const DPI = 96;
export const DPI_THRESHOLD = 150;
export const IMAGE_MAX_WIDTH = 3000;
export const DEFAULT_FONT_PROPS = {
  font: "Montserrat",
  heading_size: 18,
  title_size: 16,
  text_size: 12,
};
export const SAMPLE1_FONT_PROPS1 = {
  font: "Manrope",
  title_size: 28,
};
export const SAMPLE1_FONT_PROPS2 = {
  font: "Bebas Neue",
  title_size: 45,
};
export const SAMPLE2_FONT_PROPS1 = {
  font: "Lato",
  title_size: 40,
};
export const SAMPLE2_FONT_PROPS2 = {
  font: "Lato",
  title_size: 40,
};
export const SAMPLE2_FONT_PROPS3 = {
  font: "Lato",
  title_size: 30,
};
export const SAMPLE3_FONT_PROPS1 = {
  font: "Antonio",
  title_size: 42,
};
export const SAMPLE3_FONT_PROPS2 = {
  font: "Mulish Roman",
  title_size: 14,
};
export const SAMPLE3_FONT_PROPS3 = {
  font: "Alegreya Sans",
  title_size: 8,
};
export const SAMPLE4_FONT_PROPS1 = {
  font: "Hind",
  title_size: 28,
};
export const SAMPLE4_FONT_PROPS2 = {
  font: "Oswald",
  title_size: 42,
};
export const SAMPLE5_FONT_PROPS1 = {
  font: "Mukta",
  title_size: 28,
};
export const SAMPLE5_FONT_PROPS2 = {
  font: "Dancing Script",
  title_size: 42,
};
export const SAMPLE6_FONT_PROPS1 = {
  font: "Abril Fatface",
  title_size: 38,
};
export const SAMPLE6_FONT_PROPS2 = {
  font: "Dancing Script",
  title_size: 38,
};
export const SAMPLE6_FONT_PROPS3 = {
  font: "Abril Fatface",
  title_size: 38,
};
export const SAMPLE7_FONT_PROPS1 = {
  font: "Ubuntu",
  title_size: 42,
};
export const SAMPLE7_FONT_PROPS2 = {
  font: "Ubuntu",
  title_size: 36,
};
export const SAMPLE8_FONT_PROPS1 = {
  font: "Amatic SC",
  title_size: 42,
};
export const SAMPLE8_FONT_PROPS2 = {
  font: "Amatic SC",
  title_size: 48,
};
export const SAMPLE8_FONT_PROPS3 = {
  font: "Amatic SC",
  title_size: 48,
};
export const productTypes = {
  "4up": {
    bookmarkComment: '2.75" w x 8.5" h (4 on a Page)',
    value: "4up",
    name: "Bookmark (4-Up)",
    canvaswidth: "2.75in",
    canvasheight: "8.5in",
  },
  "3up": {
    bookmarkComment: '2.83"w x 11"h (3 on a Page)',
    value: "3up",
    name: "Bookmark (3-Up)",
    canvaswidth: "2.83in",
    canvasheight: "11in",
  },
  "2up": {
    bookmarkComment: '4.25" w x 11" h (2 on a Page)',
    value: "2up",
    name: "Bookmark (2-Up)",
    canvaswidth: "4.25in",
    canvasheight: "11in",
  },
  "8up": {
    bookmarkComment: '2.5" w x 4.25" h (8 on a Page)',
    value: "8up",
    name: "Prayer Card (8-Up)",
    canvaswidth: "2.5in",
    canvasheight: "4.25in",
  },
};

export const PROPS_TO_INCLUDE_IN_CANVAS = [
  "isDefault",
  "radius",
  "strokeWidth",
  "strokeLineJoin",
  "transparentCorners",
  "cornerColor",
  "cornerStrokeColor",
  "borderColor",
  "cornerSize",
  "padding",
  "cornerStyle",
  "type",
  "id",
  "name",
  "metaType",
  "scaleX",
  "scaleY",
  "objectCaching",
  "isLocked",
  "lockMovementX",
  "lockMovementY",
  "lockScalingX",
  "lockScalingY",
  "lockRotation",
  "isWrapping",
  "listType",
  "listBullet",
  "listStyle",
  "listCounter",
  "listNull",
  "fontStyle",
  "family",
  "fontFamily",
  "setActiveStyle",
  "fontSize",
  "fontWeight",
  "lineHeight",
  "originX",
  "originY",
  "breakWords",
  "height",
  "width",
  "editable",
  "includeDefaultValues",
  "textLines",
  "_charBounds",
  "_eventListeners",
  "_textLines",
  "applyFilters",
  "filters",
  "_dimensionAffectingProps",
  "_lineHeights",
  "_lineWidths",
  "_text",
  "_unwrappedTextLines",
  "_renderChars",
  "_renderTextLine",
  "isEndOfWrapping",
  "textAlign",
  "zoomX",
  "zoomY",
  "oCoords",
  "isRendering",
  "charSpacing",
  "textAlign",
  "styles",
  "path",
  "pathStartOffset",
  "pathSide",
  "pathAlign",
  "src",
  "imgName",
  "layerType",
  "_controlsVisibility",
  "underline",
  "linethrough",
  "crossOrigin",
];
