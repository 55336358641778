import React, { Component } from "react";
import { fabric } from "fabric";
import { initCenteringGuidelines, pixelSize} from "./Helpers";
import { addToHistory, addToHistory2, removeStates, removeStates2} from "../components/CanvasHistory";
import "./modal.css";
import config from "../../config";
import axios from "axios";
import UndoRedo from "./UndoRedo";
import { getCanvasBySide } from "./Helpers.js";
import { toast } from 'react-toastify';
import { AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import Tooltip from "react-tooltip-lite";
import petmatsafearea from '../images/petmatsafearea.svg'
import dartsafearea from '../images/dartsafearea-png.png'
import growthsafearea from '../images/growthsafearea.svg'
import { IoIosAddCircle } from "react-icons/io";
import { FaArrowLeft } from "react-icons/fa";
import { FaArrowRight } from "react-icons/fa6";
import Spin from '../components/BootstrapSpinner.js';
fabric.Canvas.prototype.originalWidth = undefined;
fabric.Canvas.prototype.originalHeight = undefined;
fabric.Canvas.prototype.cropArea = undefined;

class FabricCanvas extends Component {
    constructor(props) {
        super(props);
        this.mainCanvasContainer = React.createRef();
        this.state = {
            subtarget: null,
            frontback: "",
            canvasObj: [],
            canvas: null,
            canvas1: null,
            canvasActive: [],
            imgParam: [],
            imgdata: [],
            canvasWidth: 220,
            canvasHeight: 420,
            initCanvasWidth: 220,
            initCanvasHeight: 420,
            zoomLevel: 100,
            initZoomLevel: 1,
            widthInInch : '',
            heightInInch : '',
            showDimensions : false,
            getLoader : true,
            updateCanvasHeight : '',
            updateCanvasWidth : '',
            tempTop : '',
            disableClear : false
        };
    }

    async componentDidMount() {
        const container = this.mainCanvasContainer.current?.getBoundingClientRect();
        this.setState({
            widthInInch : this.props.state?.canvaswidth,
            heightInInch : this.props.state?.canvasheight
        })
        //Convert inches to pixel
        let canvasWidthInPixels = pixelSize(this.props.state?.canvaswidth);
        let canvasHeightInPixels = pixelSize(this.props.state?.canvasheight);
        const scale = Math.min(
            (1920 - 1920 * 0.4) / canvasWidthInPixels,
            (1080 - 1080 * 0.4) / canvasHeightInPixels
          );
        canvasWidthInPixels *= scale;
        canvasHeightInPixels *= scale;
        fabric.Canvas.originalHeight = canvasHeightInPixels;
        fabric.Canvas.originalWidth = canvasWidthInPixels;
        this.setState({
            canvasWidth: canvasWidthInPixels,
            canvasHeight: canvasHeightInPixels,
            initCanvasWidth: canvasWidthInPixels,
            initCanvasHeight: canvasHeightInPixels,
        });
        if(Object.keys(this.props.adminCanvasData).length > 0)
        {
            this.props.appLoader(true,'Loading your Product')
            let item = this.props.adminCanvasData
            if(Object.keys(item).length > 0)
            {
                this.setState({
                    showDimensions : true,
                })
                this.canvas = new fabric.Canvas("front", {
                    preserveObjectStacking: true,
                    width: item && item.width ? item.width : canvasWidthInPixels,
                    height: item && item.height ? item.height : canvasHeightInPixels,
                    backgroundColor : item && item.background ? item.background : '#fff',
                });           
                this.setbgImage(this.canvas)
                if(this.props.state?.canvastype === 'photodart')
                {
                    // Apply border-radius to the canvas container
                    var canvasContainers = document.getElementsByClassName("canvas-container");
                    if (canvasContainers.length > 0) {
                    var canvasContainer = canvasContainers[0];
                    canvasContainer.style.borderRadius = "50%"; // Adjust the value as per your preference
                    }

                    // Apply border-radius to the canvas container before pseudo-element
                    var style = document.createElement('style');
                    style.innerHTML = '.canvas-container:before { border-radius: 50%; }'; // Adjust the value as per your preference
                    document.head.appendChild(style);
                }
                this.setState({
                    canvas: this.canvas,
                });
                this.canvas1 = new fabric.Canvas("back", {
                    preserveObjectStacking: true,
                    width: canvasWidthInPixels,
                    height: canvasHeightInPixels,
                });
                this.setState({
                    canvas1: this.canvas1,
                });
                this.props.addCanvasInCanvasLIst({
                    side: "front",
                    canvas: this.canvas,
                });
                this.props.addCanvasInCanvasLIst({
                    side: "back",
                    canvas: this.canvas1,
                });
                //for canvas history save - undo / redo
                this.canvas.state = [];
                this.canvas.index = 0;
                this.canvas.stateaction = true;
                this.canvas1.state = [];
                this.canvas1.index = 0;
                this.canvas1.stateaction = true;
                initCenteringGuidelines(this.canvas);
                initCenteringGuidelines(this.canvas1);
                this.props.updateCanvas(this.canvas);
                this.props.updateCanvases("front");
                let canvas=this.canvas
                //lower
                if((container.width <= 640 && container.height > 300) && (container.width >= 565 && container.height > 300))
                {
                    this.getResolutionZoom(50, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 140)
                }
                if((container.width <= 564 && container.height > 300) && (container.width >= 522 && container.height > 300))
                {
                    this.getResolutionZoom(45, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 140)
                }
                if((container.width <= 521 && container.height > 300) && (container.width >= 480 && container.height > 300))
                {
                    this.getResolutionZoom(40, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 140)
                }
                if((container.width <= 479 && container.height > 300) && (container.width >= 438 && container.height > 300))
                {
                    this.getResolutionZoom(35, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 140)
                }
                if((container.width <= 437 && container.height > 300) && (container.width >= 394 && container.height > 300))
                {
                    this.getResolutionZoom(30, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 140)
                }
                if((container.width <= 393 && container.height > 300) && (container.width >= 352 && container.height > 300))
                {
                    this.getResolutionZoom(35, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 140)
                }
                if((container.width <= 351 && container.height > 300) && (container.width >= 300 && container.height > 300))
                {
                    this.getResolutionZoom(30, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 140)
                }
                //1024
                if((container.width <= 1024 && container.height >= 400) && (container.width <= 1024 && container.height <= 600) && (container.width >= 641 && container.height >= 400))
                {
                    this.getResolutionZoom(50, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 270)
                }
                if((container.width <= 1024 && container.height >= 601) && (container.width <= 1024 && container.height <= 768) && (container.width >= 641 && container.height >= 601))
                {
                    this.getResolutionZoom(55, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 270)
                }
                //higher
                if((container.width <= 1024 && container.height > 769) && (container.width >= 641 && container.height > 769))
                {
                    this.getResolutionZoom(55, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 140)
                }
                //1280
                if(((container.width >= 1025 && container.height >= 450) && (container.width <= 1280 && container.height <= 560)) || ((container.width === 1280 && container.height >= 450) && (container.width === 1280 && container.height <= 560)))
                {
                    this.getResolutionZoom(45, canvasWidthInPixels, canvasHeightInPixels, '40%', 170, 140)
                }
                if(((container.width >= 1025 && container.height >= 561) && (container.width <= 1280 && container.height <= 700)) || ((container.width === 1280 && container.height >= 561) && (container.width === 1280 && container.height <= 700)))
                {
                    this.getResolutionZoom(60, canvasWidthInPixels, canvasHeightInPixels, '40%', 170, 140)
                }
                if(((container.width >= 1025 && container.height >= 701) && (container.width <= 1280 && container.height <= 860)) || ((container.width === 1280 && container.height >= 701) && (container.width === 1280 && container.height <= 860)))
                {
                    this.getResolutionZoom(65, canvasWidthInPixels, canvasHeightInPixels, '43%', 170, 140)
                }
                if(((container.width >= 1025 && container.height >= 861) && (container.width <= 1280 && container.height <= 924)) || ((container.width === 1280 && container.height >= 861) && (container.width === 1280 && container.height <= 924)))
                {
                    this.getResolutionZoom(70, canvasWidthInPixels, canvasHeightInPixels, '43%', 170, 140)
                }
                if((container.width >= 1025 && container.height > 924) && (container.width <= 1280 && container.height > 924))
                {
                    this.getResolutionZoom(70, canvasWidthInPixels, canvasHeightInPixels, '43%', 170, 140)
                }
                //1366
                if(((container.width >= 1281 && container.height >= 450) && (container.width <= 1366 && container.height <= 560)) || ((container.width === 1366 && container.height >= 450) && (container.width === 1366 && container.height <= 560)))
                {
                    this.getResolutionZoom(50, canvasWidthInPixels, canvasHeightInPixels, '38%', 170, 140)
                }
                if(((container.width >= 1281 && container.height >= 561) && (container.width <= 1366 && container.height <= 768)) || ((container.width === 1366 && container.height >= 561) && (container.width === 1366 && container.height <= 768)))
                {
                    this.getResolutionZoom(55, canvasWidthInPixels, canvasHeightInPixels, '38%', 170, 140)
                }
                if((container.width >= 1281 && container.height > 561) && (container.width <= 1366 && container.height > 561))
                {
                    this.getResolutionZoom(55, canvasWidthInPixels, canvasHeightInPixels, '38%', 170, 140)
                }
                //1440
                if(((container.width >= 1367 && container.height >= 450) && (container.width <= 1440 && container.height <= 560)) || ((container.width === 1440 && container.height >= 450) && (container.width === 1440 && container.height <= 560)))
                {
                    this.getResolutionZoom(55, canvasWidthInPixels, canvasHeightInPixels, '43%', 170, 140)
                }
                if(((container.width >= 1367 && container.height >= 561) && (container.width <= 1440 && container.height <= 700)) || ((container.width === 1440 && container.height >= 561) && (container.width === 1440 && container.height <= 700)))
                {
                    this.getResolutionZoom(60, canvasWidthInPixels, canvasHeightInPixels, '43%', 170, 140)
                }
                if(((container.width >= 1367 && container.height >= 701) && (container.width <= 1440 && container.height <= 900)) || ((container.width === 1440 && container.height >= 900) && (container.width === 1440 && container.height <= 900)))
                {
                    this.getResolutionZoom(70, canvasWidthInPixels, canvasHeightInPixels, '43%', 170, 140)
                }
                if((container.width >= 1367 && container.height > 900) && (container.width <= 1440 && container.height > 900))
                {
                    this.getResolutionZoom(70, canvasWidthInPixels, canvasHeightInPixels, '43%', 170, 140)
                }
                //1600
                if(((container.width >= 1441 && container.height >= 600) && (container.width <= 1660 && container.height <= 700)) || ((container.width === 800 && container.height >= 600) && (container.width === 800 && container.height <= 700)))
                {
                    this.getResolutionZoom(65, canvasWidthInPixels, canvasHeightInPixels, '45%', 250, 140)
                }
                if(((container.width >= 1441 && container.height >= 701) && (container.width <= 1660 && container.height <= 800)) || ((container.width === 800 && container.height >= 701) && (container.width === 800 && container.height <= 800)))
                {
                    this.getResolutionZoom(75, canvasWidthInPixels, canvasHeightInPixels, '45%', 250, 140)
                }
                if(((container.width >= 1441 && container.height >= 801) && (container.width <= 1660 && container.height <= 900)) || ((container.width === 801 && container.height >= 801) && (container.width === 801 && container.height <= 900)))
                {
                    this.getResolutionZoom(85, canvasWidthInPixels, canvasHeightInPixels, '45%', 250, 140)
                }
                if(((container.width >= 1441 && container.height >= 901) && (container.width <= 1660 && container.height <= 1200)) || ((container.width === 1600 && container.height >= 901) && (container.width === 1600 && container.height <= 1200)))
                {
                    this.getResolutionZoom(90, canvasWidthInPixels, canvasHeightInPixels, '45%', 250, 140)
                }
                if((container.width >= 1441 && container.height > 1200) && (container.width <= 1660 && container.height > 1200))
                {
                    this.getResolutionZoom(90, canvasWidthInPixels, canvasHeightInPixels, '45%', 250, 140)
                }
                //1680
                if(((container.width >= 1601 && container.height >= 800) && (container.width <= 1680 && container.height <= 900)) || ((container.width === 1680 && container.height >= 800) && (container.width === 1680 && container.height <= 900)))
                {
                    this.getResolutionZoom(80, canvasWidthInPixels, canvasHeightInPixels, '40%', 200, 140)
                }
                if(((container.width >= 1601 && container.height >= 901) && (container.width <= 1680 && container.height <= 1050)) || ((container.width === 1680 && container.height >= 901) && (container.width === 1680 && container.height <= 1050)))
                {
                    this.getResolutionZoom(80, canvasWidthInPixels, canvasHeightInPixels, '40%', 200, 140)
                }
                if((container.width >= 1601 && container.height > 1050) && (container.width <= 1680 && container.height > 1050))
                {
                    this.getResolutionZoom(80, canvasWidthInPixels, canvasHeightInPixels, '40%', 200, 140)
                }
                //1920
                if(((container.width >= 1681 && container.height >= 800) && (container.width <= 1920 && container.height <= 950)) || ((container.width === 1920 && container.height >= 800) && (container.width === 1920 && container.height <= 950)))
                {
                    this.getResolutionZoom(80, canvasWidthInPixels, canvasHeightInPixels, '40%', 250, 140)
                }
                if(((container.width >= 1681 && container.height >= 951) && (container.width <= 1920 && container.height <= 1080)) || ((container.width === 1920 && container.height >= 951) && (container.width === 1920 && container.height <= 1080)))
                {
                    this.getResolutionZoom(80, canvasWidthInPixels, canvasHeightInPixels, '40%', 250, 140)
                }
                if((container.width >= 1681 && container.height > 1080) && (container.width <= 1920 && container.height > 1080))
                {
                    this.getResolutionZoom(80, canvasWidthInPixels, canvasHeightInPixels, '40%', 250, 140)
                }
                //2048
                if(((container.width >= 1921 &&  container.height <= 1536) && (container.width <= 2048 &&  container.height <= 1536)) || (container.width === 2048 && container.height <= 1536))
                {
                    this.getResolutionZoom(130, canvasWidthInPixels, canvasHeightInPixels, '42%', 400, 140)
                }
                if((container.width >= 1921 &&  container.height > 1536) && (container.width < 2048 &&  container.height > 1536))
                {
                    this.getResolutionZoom(130, canvasWidthInPixels, canvasHeightInPixels, '42%', 400, 140)
                }
                //2560
                if(((container.width >= 2049 && container.height <= 1440) && (container.width <= 2560 && container.height <= 1440)) || (container.width === 2560 && container.height <= 1440))
                {
                    this.getResolutionZoom(100, canvasWidthInPixels, canvasHeightInPixels, '40%', 400, 140)
                }
                if((container.width >= 2049 && container.height > 1440) && (container.width <= 2560 && container.height > 1440))
                {
                    this.getResolutionZoom(100, canvasWidthInPixels, canvasHeightInPixels, '40%', 400, 140)
                }
                //high
                if((container.width > 2560 && container.height > 767))
                {
                    this.getResolutionZoom(100, canvasWidthInPixels, canvasHeightInPixels, '40%', 400, 140)
                }
                if((container.width > 4000 && container.height > 767))
                {
                    this.getResolutionZoom(100, canvasWidthInPixels, canvasHeightInPixels, '40%', 400, 140)
                }
                if((container.width > 5000 && container.height > 767))
                {
                    this.getResolutionZoom(100, canvasWidthInPixels, canvasHeightInPixels, '40%', 400, 140)
                }
                await this.addObjectsToCanvas(item.objects,this.canvas)
                this.props.appLoader(false,'Loading your Product')
                setTimeout(() => {
                    if (canvas) {
                        let frontCanvas = getCanvasBySide(
                            this.props.allCanvasList,
                            "front"
                        );
                
                        let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
                        removeStates();
                        removeStates2();
                        addToHistory(frontCanvas);
                        addToHistory2(backCanvas);
                    }
                }, 100);
            }
        }else{
            this.canvas = new fabric.Canvas("front", {
                preserveObjectStacking: true,
                width: canvasWidthInPixels,
                height: canvasHeightInPixels,
                backgroundColor : '#fff',
            });
            this.setbgImage(this.canvas)
            if(this.props.state?.canvastype === 'photodart')
            {
                // Apply border-radius to the canvas container
                var canvasContainers = document.getElementsByClassName("canvas-container");
                if (canvasContainers.length > 0) {
                var canvasContainer = canvasContainers[0];
                canvasContainer.style.borderRadius = "50%"; // Adjust the value as per your preference
                }

                // Apply border-radius to the canvas container before pseudo-element
                var style = document.createElement('style');
                style.innerHTML = '.canvas-container:before { border-radius: 50%; }'; // Adjust the value as per your preference
                document.head.appendChild(style);
            }
            this.setState({
                showDimensions : true,
                getLoader : false
            })
            this.setState({
                canvas: this.canvas,
            });
            this.canvas1 = new fabric.Canvas("back", {
                preserveObjectStacking: true,
                width: canvasWidthInPixels,
                height: canvasHeightInPixels,
            });
            this.setState({
                canvas1: this.canvas1,
            });
            this.props.addCanvasInCanvasLIst({
                side: "front",
                canvas: this.canvas,
            });
            this.props.addCanvasInCanvasLIst({
                side: "back",
                canvas: this.canvas1,
            });
            //for canvas history save - undo / redo
            this.canvas.state = [];
            this.canvas.index = 0;
            this.canvas.stateaction = true;
            this.canvas1.state = [];
            this.canvas1.index = 0;
            this.canvas1.stateaction = true;
            initCenteringGuidelines(this.canvas);
            initCenteringGuidelines(this.canvas1);
            this.props.updateCanvas(this.canvas);
            this.props.updateCanvases("front");
            let canvas=this.canvas
            canvas.requestRenderAll()
            //lower
            if((container.width <= 640 && container.height > 300) && (container.width >= 565 && container.height > 300))
            {
                this.getResolutionZoom(50, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 140)
            }
            if((container.width <= 564 && container.height > 300) && (container.width >= 522 && container.height > 300))
            {
                this.getResolutionZoom(45, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 140)
            }
            if((container.width <= 521 && container.height > 300) && (container.width >= 480 && container.height > 300))
            {
                this.getResolutionZoom(40, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 140)
            }
            if((container.width <= 479 && container.height > 300) && (container.width >= 438 && container.height > 300))
            {
                this.getResolutionZoom(35, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 140)
            }
            if((container.width <= 437 && container.height > 300) && (container.width >= 394 && container.height > 300))
            {
                this.getResolutionZoom(30, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 140)
            }
            if((container.width <= 393 && container.height > 300) && (container.width >= 352 && container.height > 300))
            {
                this.getResolutionZoom(35, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 140)
            }
            if((container.width <= 351 && container.height > 300) && (container.width >= 300 && container.height > 300))
            {
                this.getResolutionZoom(30, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 140)
            }
            //1024
            if((container.width <= 1024 && container.height >= 400) && (container.width <= 1024 && container.height <= 600) && (container.width >= 641 && container.height >= 400))
            {
                this.getResolutionZoom(50, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 140)
            }
            if((container.width <= 1024 && container.height >= 601) && (container.width <= 1024 && container.height <= 768) && (container.width >= 641 && container.height >= 601))
            {
                this.getResolutionZoom(55, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 140)
            }
            //higher
            if((container.width <= 1024 && container.height > 769) && (container.width >= 641 && container.height > 769))
            {
                this.getResolutionZoom(55, canvasWidthInPixels, canvasHeightInPixels, '40%', 180, 140)
            }
            //1280
            if(((container.width >= 1025 && container.height >= 450) && (container.width <= 1280 && container.height <= 560)) || ((container.width === 1280 && container.height >= 450) && (container.width === 1280 && container.height <= 560)))
            {
                this.getResolutionZoom(45, canvasWidthInPixels, canvasHeightInPixels, '40%', 170, 140)
            }
            if(((container.width >= 1025 && container.height >= 561) && (container.width <= 1280 && container.height <= 700)) || ((container.width === 1280 && container.height >= 561) && (container.width === 1280 && container.height <= 700)))
            {
                this.getResolutionZoom(60, canvasWidthInPixels, canvasHeightInPixels, '40%', 170, 140)
            }
            if(((container.width >= 1025 && container.height >= 701) && (container.width <= 1280 && container.height <= 860)) || ((container.width === 1280 && container.height >= 701) && (container.width === 1280 && container.height <= 860)))
            {
                this.getResolutionZoom(65, canvasWidthInPixels, canvasHeightInPixels, '43%', 170, 140)
            }
            if(((container.width >= 1025 && container.height >= 861) && (container.width <= 1280 && container.height <= 924)) || ((container.width === 1280 && container.height >= 861) && (container.width === 1280 && container.height <= 924)))
            {
                this.getResolutionZoom(70, canvasWidthInPixels, canvasHeightInPixels, '43%', 170, 140)
            }
            if((container.width >= 1025 && container.height > 924) && (container.width <= 1280 && container.height > 924))
            {
                this.getResolutionZoom(70, canvasWidthInPixels, canvasHeightInPixels, '43%', 170, 140)
            }
            //1366
            if(((container.width >= 1281 && container.height >= 450) && (container.width <= 1366 && container.height <= 560)) || ((container.width === 1366 && container.height >= 450) && (container.width === 1366 && container.height <= 560)))
            {
                this.getResolutionZoom(50, canvasWidthInPixels, canvasHeightInPixels, '38%', 170, 140)
            }
            if(((container.width >= 1281 && container.height >= 561) && (container.width <= 1366 && container.height <= 768)) || ((container.width === 1366 && container.height >= 561) && (container.width === 1366 && container.height <= 768)))
            {
                this.getResolutionZoom(55, canvasWidthInPixels, canvasHeightInPixels, '38%', 170, 140)
            }
            if((container.width >= 1281 && container.height > 561) && (container.width <= 1366 && container.height > 561))
            {
                this.getResolutionZoom(55, canvasWidthInPixels, canvasHeightInPixels, '38%', 170, 140)
            }
            //1440
            if(((container.width >= 1367 && container.height >= 450) && (container.width <= 1440 && container.height <= 560)) || ((container.width === 1440 && container.height >= 450) && (container.width === 1440 && container.height <= 560)))
            {
                this.getResolutionZoom(55, canvasWidthInPixels, canvasHeightInPixels, '43%', 170, 140)
            }
            if(((container.width >= 1367 && container.height >= 561) && (container.width <= 1440 && container.height <= 700)) || ((container.width === 1440 && container.height >= 561) && (container.width === 1440 && container.height <= 700)))
            {
                this.getResolutionZoom(60, canvasWidthInPixels, canvasHeightInPixels, '43%', 170, 140)
            }
            if(((container.width >= 1367 && container.height >= 701) && (container.width <= 1440 && container.height <= 900)) || ((container.width === 1440 && container.height >= 900) && (container.width === 1440 && container.height <= 900)))
            {
                this.getResolutionZoom(70, canvasWidthInPixels, canvasHeightInPixels, '43%', 170, 140)
            }
            if((container.width >= 1367 && container.height > 900) && (container.width <= 1440 && container.height > 900))
            {
                this.getResolutionZoom(70, canvasWidthInPixels, canvasHeightInPixels, '43%', 170, 140)
            }
            //1600
            if(((container.width >= 1441 && container.height >= 600) && (container.width <= 1660 && container.height <= 700)) || ((container.width === 800 && container.height >= 600) && (container.width === 800 && container.height <= 700)))
            {
                this.getResolutionZoom(65, canvasWidthInPixels, canvasHeightInPixels, '45%', 250, 140)
            }
            if(((container.width >= 1441 && container.height >= 701) && (container.width <= 1660 && container.height <= 800)) || ((container.width === 800 && container.height >= 701) && (container.width === 800 && container.height <= 800)))
            {
                this.getResolutionZoom(75, canvasWidthInPixels, canvasHeightInPixels, '45%', 250, 140)
            }
            if(((container.width >= 1441 && container.height >= 801) && (container.width <= 1660 && container.height <= 900)) || ((container.width === 801 && container.height >= 801) && (container.width === 801 && container.height <= 900)))
            {
                this.getResolutionZoom(85, canvasWidthInPixels, canvasHeightInPixels, '45%', 250, 140)
            }
            if(((container.width >= 1441 && container.height >= 901) && (container.width <= 1660 && container.height <= 1200)) || ((container.width === 1600 && container.height >= 901) && (container.width === 1600 && container.height <= 1200)))
            {
                this.getResolutionZoom(90, canvasWidthInPixels, canvasHeightInPixels, '45%', 250, 140)
            }
            if((container.width >= 1441 && container.height > 1200) && (container.width <= 1660 && container.height > 1200))
            {
                this.getResolutionZoom(90, canvasWidthInPixels, canvasHeightInPixels, '45%', 250, 140)
            }
            //1680
            if(((container.width >= 1601 && container.height >= 800) && (container.width <= 1680 && container.height <= 900)) || ((container.width === 1680 && container.height >= 800) && (container.width === 1680 && container.height <= 900)))
            {
                this.getResolutionZoom(80, canvasWidthInPixels, canvasHeightInPixels, '40%', 200, 140)
            }
            if(((container.width >= 1601 && container.height >= 901) && (container.width <= 1680 && container.height <= 1050)) || ((container.width === 1680 && container.height >= 901) && (container.width === 1680 && container.height <= 1050)))
            {
                this.getResolutionZoom(80, canvasWidthInPixels, canvasHeightInPixels, '40%', 200, 140)
            }
            if((container.width >= 1601 && container.height > 1050) && (container.width <= 1680 && container.height > 1050))
            {
                this.getResolutionZoom(80, canvasWidthInPixels, canvasHeightInPixels, '40%', 200, 140)
            }
            //1920
            if(((container.width >= 1681 && container.height >= 800) && (container.width <= 1920 && container.height <= 950)) || ((container.width === 1920 && container.height >= 800) && (container.width === 1920 && container.height <= 950)))
            {
                this.getResolutionZoom(80, canvasWidthInPixels, canvasHeightInPixels, '40%', 250, 140)
            }
            if(((container.width >= 1681 && container.height >= 951) && (container.width <= 1920 && container.height <= 1080)) || ((container.width === 1920 && container.height >= 951) && (container.width === 1920 && container.height <= 1080)))
            {
                this.getResolutionZoom(80, canvasWidthInPixels, canvasHeightInPixels, '40%', 250, 140)
            }
            if((container.width >= 1681 && container.height > 1080) && (container.width <= 1920 && container.height > 1080))
            {
                this.getResolutionZoom(80, canvasWidthInPixels, canvasHeightInPixels, '40%', 250, 140)
            }
            //2048
            if(((container.width >= 1921 &&  container.height <= 1536) && (container.width <= 2048 &&  container.height <= 1536)) || (container.width === 2048 && container.height <= 1536))
            {
                this.getResolutionZoom(130, canvasWidthInPixels, canvasHeightInPixels, '42%', 400, 140)
            }
            if((container.width >= 1921 &&  container.height > 1536) && (container.width < 2048 &&  container.height > 1536))
            {
                this.getResolutionZoom(130, canvasWidthInPixels, canvasHeightInPixels, '42%', 400, 140)
            }
            //2560
            if(((container.width >= 2049 && container.height <= 1440) && (container.width <= 2560 && container.height <= 1440)) || (container.width === 2560 && container.height <= 1440))
            {
                this.getResolutionZoom(100, canvasWidthInPixels, canvasHeightInPixels, '40%', 400, 140)
            }
            if((container.width >= 2049 && container.height > 1440) && (container.width <= 2560 && container.height > 1440))
            {
                this.getResolutionZoom(100, canvasWidthInPixels, canvasHeightInPixels, '40%', 400, 140)
            }
            //high
            if((container.width > 2560 && container.height > 767))
            {
                this.getResolutionZoom(100, canvasWidthInPixels, canvasHeightInPixels, '40%', 400, 140)
            }
            if((container.width > 4000 && container.height > 767))
            {
                this.getResolutionZoom(100, canvasWidthInPixels, canvasHeightInPixels, '40%', 400, 140)
            }
            if((container.width > 5000 && container.height > 767))
            {
                this.getResolutionZoom(100, canvasWidthInPixels, canvasHeightInPixels, '40%', 400, 140)
            }
            setTimeout(() => {
                if (canvas) {
                    let frontCanvas = getCanvasBySide(
                        this.props.allCanvasList,
                        "front"
                    );
            
                    let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
                    removeStates();
                    removeStates2();
                    addToHistory(frontCanvas);
                    addToHistory2(backCanvas);
                }
            }, 100);
        }
    }

    getResolutionZoom = (zoomRange, canvasWidthInPixels, canvasHeightInPixels, tempTop, tempHeight, tempWidth) => {
        let zoomLevel = parseInt(zoomRange, 10);
        const zoomValue = zoomLevel / 100;
        this.setState({
            zoomLevel : zoomLevel,
            initCanvasHeight : canvasHeightInPixels,
            initCanvasWidth : canvasWidthInPixels,
            tempTop : tempTop,
            tempHeight : tempHeight,
            tempWidth : tempWidth
        })
        const newZoomLevel = this.state.initZoomLevel * zoomValue;
        const newCanvasHeight = parseInt(canvasHeightInPixels * zoomValue);
        const newCanvasWidth = parseInt(canvasWidthInPixels * zoomValue);
        this.setState({
            updateCanvasHeight : newCanvasHeight,
            updateCanvasWidth : newCanvasWidth
        })
        this.canvas.setHeight(newCanvasHeight);
        this.canvas.setWidth(newCanvasWidth);
        this.canvas.setZoom(newZoomLevel);
        this.props.saveZoom(newCanvasHeight,newCanvasWidth,newZoomLevel)
    }

    async addObjectsToCanvas(array, canvas) {
        for (const obj of array) {
          obj.cornerColor = '#1babeb';
          obj.cornerStrokeColor = '#1babeb';
          obj.borderColor = '#1babeb';
          obj.cornerSize = 5;
          obj.padding = 5;
          obj.cornerStyle = 'circle';
          obj.transparentCorners = false;
          obj.radius = 70;
          switch (obj.type) {
            case 'image':
                await this.addImageToCanvas(canvas, obj);
                break;
            case 'textbox':
                await this.addTextboxToCanvas(canvas, obj);
                break;
            case 'circle':
                await this.addCircleToCanvas(canvas, obj);
                break;
            case 'rect':
                await this.addRectToCanvas(canvas, obj);
                break;
            case 'polygon':
                await this.addPolygonToCanvas(canvas, obj);
                break;
            default:
              break;
          }
        }
    }
    
    setbgImage = (canvas) => {
        if(this.props.state?.canvastype === 'photodart'){
            var image = new Image();
            image.src = dartsafearea;
            image.crossOrigin = "anonymous";
            image.crossDomain = true;
            image.onload = () => {
                var img = new fabric.Image(image);
                var HideControls = {
                    'mb': false,
                    'mt': false,
                };
                img.setControlsVisibility(HideControls);
                img.set({
                    top : (fabric.Canvas.originalHeight / 2) - 1.9,
                    type : 'image',
                    originX: "center",
                    originY: "center",
                    left: (fabric.Canvas.originalWidth / 2) - 2.9,
                    src : dartsafearea,
                });
                var scaleX = fabric.Canvas.originalWidth / img.width;
                var scaleY = fabric.Canvas.originalHeight / img.height;
                var scale = Math.min(scaleX, scaleY);
                img.set({ scaleX: scale, scaleY: scale });
                canvas.setOverlayImage(img, canvas.renderAll.bind(canvas));
                img.setCoords();
                canvas.renderAll();
            };
        }else if(this.props.state?.canvastype === 'growthchart')
        {
            var image = new Image();
            image.src = growthsafearea;
            image.crossOrigin = "anonymous";
            image.crossDomain = true;
            image.onload = () => {
                var img = new fabric.Image(image);
                var HideControls = {
                    'mb': false,
                    'mt': false,
                };
                img.setControlsVisibility(HideControls);
                img.set({
                    top : (fabric.Canvas.originalHeight / 2) - 1,
                    type : 'image',
                    originX: "center",
                    originY: "center",
                    left: (fabric.Canvas.originalWidth / 2) - 1,
                    src : growthsafearea,
                });
                // var scaleX = fabric.Canvas.originalWidth / img.width;
                // var scaleY = fabric.Canvas.originalHeight / img.height;
                // var scale = Math.min(scaleX, scaleY);
                img.set({ scaleX: 2.2, scaleY: 1.77 });
                canvas.setOverlayImage(img, canvas.renderAll.bind(canvas));
                img.setCoords();
                canvas.renderAll();
            };
        }else{
            var image = new Image();
            image.src = petmatsafearea;
            image.crossOrigin = "anonymous";
            image.crossDomain = true;
            image.onload = () => {
                var img = new fabric.Image(image);
                var HideControls = {
                    'mb': false,
                    'mt': false,
                };
                img.setControlsVisibility(HideControls);
                img.set({
                    top : (fabric.Canvas.originalHeight / 2) - 1.9 ,
                    type : 'image',
                    originX: "center",
                    originY: "center",
                    left: (fabric.Canvas.originalWidth / 2) - 2.9,
                    src : petmatsafearea,
                });
                var scaleX = fabric.Canvas.originalWidth / img.width;
                var scaleY = fabric.Canvas.originalHeight / img.height;
                var scale = Math.min(scaleX, scaleY);
                img.set({ scaleX: scale, scaleY: scale });
                canvas.setOverlayImage(img, canvas.renderAll.bind(canvas));
                img.setCoords();
                canvas.renderAll();
            };
        }
    }

    getImageTypeFromDataURL = (url) => {
    // Split the URL by "/"
    const urlParts = url.split('/');

    // Get the last part of the URL, which is the filename
    const filename = urlParts[urlParts.length - 1];

    // Split the filename by "." to get the file extension
    const filenameParts = filename.split('.');

    // Get the last part of the filenameParts, which is the file extension
    const fileExtension = filenameParts[filenameParts.length - 1];

    // Convert the file extension to lowercase (optional)
    const lowercaseFileExtension = fileExtension.toLowerCase();

    return lowercaseFileExtension
    }

    addImageToCanvas(canvas, obj) {
    let imageUrlWithoutQueryParam = obj.src.split('?')[0];
    let imageUrl = imageUrlWithoutQueryParam +"?v="+Date.now()
    var imageType = this.getImageTypeFromDataURL(imageUrlWithoutQueryParam);
    return new Promise((resolve) => {
    var HideControls = {
        'mb': false,
        'mt': false,
        };
        const imgElement = new Image();
        imgElement.crossOrigin = "anonymous"; // Set crossOrigin here
    
        imgElement.onload = function () {
        if (imageType === "svg") {
            // Handle SVG separately
            fabric.loadSVGFromURL(imageUrl, function (objects, options) {
                const group = new fabric.Group(objects, options);
                group.set(obj);
                group.setControlsVisibility(HideControls);
                canvas.add(group);
                resolve();
            });
        } else {
            // For other formats (e.g., PNG)
            fabric.Image.fromURL(imageUrl, function (img) {
                img.setElement(imgElement);
                img.set(obj);
                img.setControlsVisibility(HideControls);
                canvas.add(img);
                resolve();
            });
        }
        };
        imgElement.src = imageUrl; // Set the image source URL
    });
    }
    
    addTextboxToCanvas(canvas, obj) {
    return new Promise((resolve) => {
    var HideControls = {
        'mb': false,
        'mt': false,
        };
        var textObj = new fabric.Textbox(obj.text, obj);
        textObj.setControlsVisibility(HideControls);
        if(textObj?.listType === 'number')
        {
            textObj.set( {
                name: "list",
                fontStyle: obj?.fontStyle ? obj?.fontStyle : 'normal' ,
                fontSize: obj.fontSize,
                fontWeight: obj?.fontWeight ? obj?.fontWeight : 'normal',
                objectCaching: false,
                isWrapping: false,
                listType: "number",
                listBullet: "●",
                listCounter: 0,
                listNull:""
            })
            const renderTextLine = function (method, ctx, line, left, top, lineIndex) {
                const style0 = this.getCompleteStyleDeclaration(lineIndex, 0),
                    bullet = this.listType === "bullet"
                            ? [this.listBullet]
                            :(this.listType==="number"?[this.listCounter + 1 + "."]:[this.listNull]) ,
                    bulletLeft = left - style0.fontSize - 2;
                if (line.length) {
                    if (!this.isWrapping) {
                        // render the bullet
                        this._renderChars(method, ctx, bullet, bulletLeft, top, lineIndex);
                        this.isWrapping = !this.isEndOfWrapping(lineIndex);
                        if (!this.isWrapping) this.listCounter++;
                    } else if (this.isEndOfWrapping(lineIndex)) {
                        this.isWrapping = false;
                        this.listCounter++;
                    }
                }
                if (lineIndex === this.textLines.length - 1) {
                    this.isWrapping = false;
                    this.listCounter = 0;
                }
                // render the text line
                this._renderChars(method, ctx, line, left, top, lineIndex);
            };
            textObj._renderTextLine = renderTextLine;
            canvas.renderAll()
        }
        if(textObj?.listType === 'bullet')
        {
            textObj.set({
                name: "list",
                fontStyle: obj?.fontStyle ? obj?.fontStyle : 'normal' ,
                fontSize: obj.fontSize,
                fontWeight: obj?.fontWeight ? obj?.fontWeight : 'normal',
                objectCaching: false,
                isWrapping: false,
                listType: "bullet",
                listBullet: "●",
                listCounter: 0,
                listNull:""
            })
            const renderTextLine = function (method, ctx, line, left, top, lineIndex) {
                const style0 = this.getCompleteStyleDeclaration(lineIndex, 0),
                    bullet = this.listType === "bullet"
                            ? [this.listBullet]
                            :(this.listType==="number"?[this.listCounter + 1 + "."]:[this.listNull]) ,
                    bulletLeft = left - style0.fontSize - 2;
                if (line.length) {
                    if (!this.isWrapping) {
                        // render the bullet
                        this._renderChars(method, ctx, bullet, bulletLeft, top, lineIndex);
                        this.isWrapping = !this.isEndOfWrapping(lineIndex);
                        if (!this.isWrapping) this.listCounter++;
                    } else if (this.isEndOfWrapping(lineIndex)) {
                        this.isWrapping = false;
                        this.listCounter++;
                    }
                }
                if (lineIndex === this.textLines.length - 1) {
                    this.isWrapping = false;
                    this.listCounter = 0;
                }
                // render the text line
                this._renderChars(method, ctx, line, left, top, lineIndex);
            };
            textObj._renderTextLine = renderTextLine;
            canvas.renderAll()
        }
        canvas.add(textObj);
        resolve();
    });
    }

    addCircleToCanvas(canvas, obj) {
    return new Promise((resolve) => {
    var HideControls = {
    'mb': false,
    'mt': false,
    };
    var circleObj = new fabric.Circle(obj);
    circleObj.setControlsVisibility(HideControls);
    canvas.add(circleObj);
    resolve();
    });
    }

    addRectToCanvas(canvas, obj) {
    return new Promise((resolve) => {
    var HideControls = {
    'mb': false,
    'mt': false,
    };
    var rectObj = new fabric.Rect(obj);
    rectObj.setControlsVisibility(HideControls);
    canvas.add(rectObj);
    resolve();
    });
    }

    addPolygonToCanvas(canvas, obj) {
    return new Promise((resolve) => {
    var HideControls = {
    'mb': false,
    'mt': false,
    };
    var polyObj = new fabric.Polygon(obj.points);
    polyObj.set(obj);
    polyObj.setControlsVisibility(HideControls);
    canvas.add(polyObj);
    resolve();
    });
    }

    handleZoomIn = () => {
        const { canvas, canvas1, zoomLevel, initZoomLevel } = this.state;
        let newZoomLevel;
        if(window.innerWidth <= 640){
            newZoomLevel = zoomLevel >= 65 ? zoomLevel : zoomLevel + 5;
        }else{
            newZoomLevel = zoomLevel + 5;
        }
        if (newZoomLevel <= 200) {
          const zoomValue = newZoomLevel / 100;
          const newCanvasHeight = parseInt(this.state.initCanvasHeight * zoomValue);
          const newCanvasWidth = parseInt(this.state.initCanvasWidth * zoomValue);
        this.setState({
            updateCanvasHeight : newCanvasHeight,
            updateCanvasWidth : newCanvasWidth
        })
          canvas.setHeight(newCanvasHeight);
          canvas.setWidth(newCanvasWidth);
          canvas.setZoom(initZoomLevel * zoomValue);
    
          canvas1.setHeight(newCanvasHeight);
          canvas1.setWidth(newCanvasWidth);
          canvas1.setZoom(initZoomLevel * zoomValue);
    
          this.setState({ zoomLevel: newZoomLevel });
          if (canvas) {
            let frontCanvas = getCanvasBySide(
                this.props.allCanvasList,
                "front"
            );
    
            let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
            removeStates();
            removeStates2();
            addToHistory(frontCanvas);
            addToHistory2(backCanvas);
        }
        }
    };
    
    scrollLeft = () => {
        const element = document.getElementById('temp');
        if (element) {
          element.scrollLeft -= 100; // Adjust the scroll amount as needed
        }
    };
    
    scrollRight = () => {
        const element = document.getElementById('temp');
        if (element) {
          element.scrollLeft += 100; // Adjust the scroll amount as needed
        }
    };

    handleZoomOut = () => {
    const { canvas, canvas1, zoomLevel, initZoomLevel } = this.state;
    const newZoomLevel = zoomLevel - 5;

    if (newZoomLevel >= 10) {
        const zoomValue = newZoomLevel / 100;
        const newCanvasHeight = parseInt(this.state.initCanvasHeight * zoomValue);
        const newCanvasWidth = parseInt(this.state.initCanvasWidth * zoomValue);
        this.setState({
        updateCanvasHeight : newCanvasHeight,
        updateCanvasWidth : newCanvasWidth
    })
        canvas.setHeight(newCanvasHeight);
        canvas.setWidth(newCanvasWidth);
        canvas.setZoom(initZoomLevel * zoomValue);

        canvas1.setHeight(newCanvasHeight);
        canvas1.setWidth(newCanvasWidth);
        canvas1.setZoom(initZoomLevel * zoomValue);

        this.setState({ zoomLevel: newZoomLevel });
    }
    if (canvas) {
        let frontCanvas = getCanvasBySide(
            this.props.allCanvasList,
            "front"
        );

        let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
        removeStates();
        removeStates2();
        addToHistory(frontCanvas);
        addToHistory2(backCanvas);
    }
    };
    
    getZoom = (zoomLevel, width, height) => {
        this.setState({
            zoomLevel : zoomLevel * 100,
            updateCanvasWidth : width,
            updateCanvasHeight : height
        })
    }

    handleMainAreaClick = (e) => {
        let canvas = this.props.state.canvas
        const clickedElement = e.target;
        //console.log('clickedElement.classList',clickedElement.classList[0])
        // Check if the clicked element is not one of the excluded child elements
        if (
            clickedElement === this.mainCanvasContainer.current || clickedElement.classList[0] === 'bleed-line' || clickedElement.classList[0] === 'safety-line'
        ) {
            // Trigger your click event here
            if (canvas) {
                canvas.discardActiveObject();
                let obj=canvas.getObjects().find((item)=>{return item.name==="selectionRect"})
                canvas.remove(obj)
            }
        }
    }

    clearButton = () => {
        const query = new URLSearchParams(window.location.search);
        const pid = query.get("pid");
        const userId = query.get("u");
        const token = query.get("token");
        const duid = query.get("duid");
        if(pid && userId && token && !this.props.state.isAdmin && !duid)
        {
            this.props.clearModal()
        }else{
            this.clearProduct()
        }
    }

    clearProduct = () => {
        this.setState({
            disableClear : true
        })
        const query = new URLSearchParams(window.location.search);
        const pid = query.get("pid");
        this.props.state.canvas.clear()
        this.props.multipleLayerDelete()
        this.props.appLoader(true,'Clearing your Product')
        if(pid)
        {
            let canvas = JSON.parse(localStorage.getItem('canvas'))
            if(canvas)
            {
                localStorage.removeItem('canvas')
                this.getadmindata()
            }else{
                this.getadmindata()
            }
        }
    }

    getadmindata = async() => {
        const query = new URLSearchParams(window.location.search);
        const pid = query.get("pid");
        const headers = {
            "Content-Type": "application/json;charset=UTF-8",
        };
        let apiDynamicUrlAddress1 = config.default.api1.host;
        let apiUrl1 = `${apiDynamicUrlAddress1}/getProductImageDetails?productId=${pid}&role=admin`;
        try {
            const response = await axios.get(apiUrl1, {
                mode: "cors",
                headers: headers,
            });
            if(response.data.code === 200)
            {
                let canvasdata = response.data.data[0].json
                this.props.state.canvas.backgroundColor = response.data.data[0].json.background
                await this.addObjectsToCanvas(canvasdata.objects,this.props.state.canvas)
                this.props.state.canvas.renderAll()
                this.props.multipleLayerDelete()
                this.setbgImage(this.props.state.canvas)
                this.props.appLoader(false,'Clearing your Product')
                this.setState({
                    disableClear : false
                })
                setTimeout(() => {
                    if (this.props.state.canvas) {
                        let frontCanvas = getCanvasBySide(
                            this.props.allCanvasList,
                            "front"
                        );
                        let backCanvas = getCanvasBySide(this.props.allCanvasList, "back");
                        removeStates();
                        removeStates2();
                        addToHistory(frontCanvas);
                        addToHistory2(backCanvas);
                    }
                }, 100);
            }
            else{
                toast.error(response.data.Message, { position: toast.POSITION.TOP_RIGHT });
            }
        } catch (error) {
            toast.error("Get Product ImageDetails API Failed, Try again Later", { position: toast.POSITION.TOP_RIGHT });
        }
    }
    mobileSidebar = () => {
        this.props.mobiletoggleSidebar()
    }
    render() {
        const displayzoomLevel = parseInt(this.state.zoomLevel);
        return (
            <div>
                <div
                    id="main-area"
                    ref={this.mainCanvasContainer}
                    className="main-area"
                    style={{
                        position: "absolute",
                        width: "100%",
                        background : "#f0f0f0"
                    }}
                    onClick={this.handleMainAreaClick}
                >
                    <div
                        id="temp"
                        className="temp-canvas"
                        style={{
                            display : "grid",
                            position: "absolute",
                            top: this.state.tempTop,
                            transform : "translateY(-50%)",
                            height : this.state?.updateCanvasHeight >= this.mainCanvasContainer.current?.getBoundingClientRect().height - this.state.tempHeight ? this.mainCanvasContainer.current?.getBoundingClientRect().height - this.state.tempHeight : "auto",
                            width : window.innerWidth > 394 ? (this.state?.updateCanvasWidth >= this.mainCanvasContainer.current?.getBoundingClientRect().width - this.state.tempWidth ? this.mainCanvasContainer.current?.getBoundingClientRect().width - this.state.tempWidth : "auto") : (this.state?.updateCanvasWidth >= this.mainCanvasContainer.current?.getBoundingClientRect().width ? this.mainCanvasContainer.current?.getBoundingClientRect().width  : "auto"),
                            overflowY : window.innerWidth > 394 ? (this.state?.updateCanvasHeight >= this.mainCanvasContainer.current?.getBoundingClientRect().height - this.state.tempHeight ?  "auto" : 'hidden') : (this.state?.updateCanvasHeight >= this.mainCanvasContainer.current?.getBoundingClientRect().height - this.state.tempHeight ?  "auto" : 'hidden'),
                            overflowX : window.innerWidth > 394 ? (this.state?.updateCanvasWidth >= this.mainCanvasContainer.current?.getBoundingClientRect().width - this.state.tempWidth ?  "auto" : 'hidden') : (this.state?.updateCanvasWidth >= this.mainCanvasContainer.current?.getBoundingClientRect().width - this.state.tempWidth ?  "auto" : 'hidden')
                        }}
                    >
                        {this.props.responsive >= 641 && this.state.showDimensions && displayzoomLevel >= 45 && (this.state?.updateCanvasHeight <= this.mainCanvasContainer.current?.getBoundingClientRect().height - this.state.tempHeight) ? (
                            <div className="safety-bleed-outer">
                                <p className="canvas-dimension">{this.state.widthInInch} x {this.state.heightInInch}(in)</p>
                                 <Tooltip content="Anything outside this line will not be printed." direction="up">
                                    <button className="bleed-line">Trim Line</button>
                                </Tooltip>
                                <Tooltip content="Anything outside this line may be trimmed off in the printing process." direction="up">
                                    <button className="safety-line">Safe Area</button>
                                </Tooltip>
                            </div>
                        )
                        :
                            <div className="safety-bleed-outer">
                                <p className="canvas-dimension">{this.state.widthInInch} x {this.state.heightInInch} {""} (in)</p>
                                 <Tooltip content="Anything outside this line will not be printed." direction="up">
                                    <button className="bleed-line">Trim Line</button>
                                </Tooltip>
                                <Tooltip content="Anything outside this line may be trimmed off in the printing process." direction="up">
                                    <button className="safety-line">Safe Area</button>
                                </Tooltip>
                            </div>
                        }
                        <div id="abs1" className="canvasfrontback " style={{height : this.state?.updateCanvasHeight}}>
                        <canvas id="front"></canvas>
                        </div>
                        <div
                            id="canvasElement"
                            style={{ display: "none" }}
                            className="canvasfrontback"
                        >
                            <canvas id="canvas-element"></canvas>
                        </div>
                        <div className="canvas-spinner">{this.props.isSpin && (<Spin/>)}</div>
                    </div>
                    {(window.innerWidth <=394 && this.state?.updateCanvasWidth >= this.mainCanvasContainer.current?.getBoundingClientRect().width) && (
                        <div className="scroll-arrows">
                            <FaArrowLeft onClick={this.scrollLeft}/>
                            <FaArrowRight onClick={this.scrollRight}/>
                        </div>
                    )}
                    {(window.innerWidth > 394 && this.state?.updateCanvasWidth >= this.mainCanvasContainer.current?.getBoundingClientRect().width - 140) && (
                        <div className="scroll-arrows">
                            <FaArrowLeft onClick={this.scrollLeft}/>
                            <FaArrowRight onClick={this.scrollRight}/>
                        </div>
                    )}
                    <div className="bottom-outer">
                        <span
                        className="undoredo"
                        >
                        <UndoRedo
                            allCanvasList={this.props.allCanvasList}
                            canvas={this.canvas}
                            getZoom = {this.getZoom}
                            triggerState = {this.props.triggerState}
                            setbgImage = {this.setbgImage}
                        />
                        </span>
                        <div
                            className="zoom-range"
                        >
                            <AiOutlineZoomIn onClick={this.handleZoomIn}/>
                                <span className="zoom-value">{displayzoomLevel}%</span>
                            <AiOutlineZoomOut onClick={this.handleZoomOut}/>
                        </div>
                        <div className="clr-btn-outer">
                            <button className="clr-btn" onClick={this.clearButton} disabled={this.state.disableClear || this.props?.state?.canvas?.getObjects().length === 0}>Clear</button>
                        </div>
                    </div>
                    <div className="mobile-side-bar">
                        <button onClick={this.mobileSidebar}>
                            Design Tools
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default FabricCanvas;